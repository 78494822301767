import {DialogClose} from '@radix-ui/react-dialog';
import clsx from 'clsx';
import {AnimatePresence} from 'framer-motion';
import {useIsMedium} from 'lib/useBreakpoints';
import {Game, GameStatus, UserGameStatus} from 'types/Game';
import {DialogPortal, DialogRoot} from 'ui-kit/Dialog';
import {Icons} from 'ui-kit/Icons';
import {ProgressBar} from 'ui-kit/ProgressBar';
import {formatAddress, getTokenBalance} from 'utils/web3';
import {useWeb3React} from '@web3-react/core';
import {formatEther, parseEther} from 'ethers/lib/utils';
import {FormEvent, useEffect, useMemo, useState} from 'react';
import {getCoin, getSortedBets, getTokenDecimals} from 'utils/game';
import {useWallet} from '@solana/wallet-adapter-react';
import {SolanaValue} from 'ui-kit/SolanaValue';
import {useTimeDifference} from 'hooks/useTimeDifference';
import {FormProvider, useForm} from 'react-hook-form';
import {GameDepositForm} from 'ui-kit/GameDepositDialog/GameDepositForm';
import {GameDepositSuccess} from 'ui-kit/GameDepositDialog/GameDepositSuccess';
import {
  GameDepositScheme,
  GameDepositType,
} from 'ui-kit/GameDepositDialog/scheme';
import {zodResolver} from '@hookform/resolvers/zod';
import {errors} from 'ethers';
import {Coin, EthersError} from 'types';
import {gameService} from 'services/games';
import {TARGET_CHAIN} from 'lib/web3-react/constants/chains';
import {useChain} from 'hooks/useChain';
import {connection, TOKEN_ADDRESS} from '../../constants';
import {useDashboardStore} from 'screens/Dashboard/store/store';
import {useSearchParams} from 'react-router-dom';
import {ConnectWalletDialog} from 'screens/Dashboard/components/ConnectWalletDialog';
import {
  formatNumberWithCommas,
  formatNumberWithFixedDecimals,
} from 'lib/formatNum';
import {Price} from 'services/price';

const SHARE_URL =
  process.env.REACT_APP_SHARE_URL || 'https://share.ponzi.market';

function formatNumber(n: number | string, price: number) {
  if (!n) return '0';
  if (typeof n === 'string') {
    if (price) {
      let num;
      if (Number(n) * price < 1) num = fixedFormatNumber(Number(n) * price, 5);
      else num = (Number(n) * price).toLocaleString();
      return `$${num}`;
    }
    return `${Number(n).toLocaleString()}`;
  }
  if (price) {
    let num;
    if (Number(n) * price < 1) num = fixedFormatNumber(Number(n) * price, 5);
    else num = (Number(n) * price).toLocaleString();
    return `$${num}`;
  }
  return `${n.toLocaleString()} `;
}

function fixedFormatNumber(n: number, decimals: number) {
  const formatted = n.toLocaleString();
  const decimalsStr = n.toFixed(decimals);
  const split = formatted.split('.');
  return `${split[0]}.${decimalsStr.split('.')[1]}`;
}

interface GamePlayersDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  game: Game;
  setIsOpen: (isOpen: boolean) => void;
}

/**
 * Fallback value for PONZI to USD conversion
 */
const PONZI_TO_USD = 0.0003676;

export function GamePlayersDialog({
  isOpen,
  onOpenChange,
  game,
}: GamePlayersDialogProps) {
  const [ponziPrice, setPonziPrice] = useState(PONZI_TO_USD);
  const [icon, setIcon] = useState<'link' | 'check'>('link');
  const {addToast} = useDashboardStore();
  const {bets, roi} = game;
  const isMd = useIsMedium();
  const [decimals, setDecimals] = useState<number>(6);
  const [jackpotInUsd, setJackpotInUSD] = useState(
    (Number(game.softPool) / 10 ** decimals) * ponziPrice
  );
  const [minDepositInUsd, setMinDepositInUSD] = useState(
    game.minDeposit * ponziPrice
  );
  const [maxDepositInUsd, setMaxDepositInUSD] = useState(
    game.maxDeposit * ponziPrice
  );

  const {connected, publicKey, signTransaction} = useWallet();
  const {provider, account} = useWeb3React();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState<'deposit' | 'success'>('deposit');
  const [error, setError] = useState<string | null>(null);
  const [isDepositDialogOpen, setIsDepositDialogOpen] = useState(false);
  const [referral, setReferral] = useState<string | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const [coin, setCoin] = useState<Coin | undefined>(undefined);
  useEffect(() => {
    const retreiveIcon = async () => {
      const c = await getCoin(game.currencyAddress);
      if (c) {
        setCoin(c);
      }
      const d = await getTokenDecimals(game.currencyAddress);
      if (d) setDecimals(d);
    };
    retreiveIcon().catch(console.error);
  }, []);

  useEffect(() => {
    const fetchPrice = async () => {
      const solPrice = await Price.fetchPrice(game.currencyAddress);
      setPonziPrice(Number(solPrice));
      setJackpotInUSD(
        (Number(game.softPool) / 10 ** decimals) * Number(solPrice)
      );
      setMinDepositInUSD(game.minDeposit * Number(solPrice));
      setMaxDepositInUSD(game.maxDeposit * Number(solPrice));
    };
    fetchPrice().catch(console.error);
  }, [setPonziPrice]);

  useEffect(() => {
    const ref = searchParams.get('ref');
    const exists = localStorage.getItem(game.id);
    if (ref) {
      setReferral(ref);
      if (exists) localStorage.removeItem(game.id);
      localStorage.setItem(game.id, ref);
    } else if (exists) {
      setReferral(exists);
    }
  }, []);

  const endsInMs = new Date(game.finishAt).getTime();
  const {days, hours, minutes, seconds} = useTimeDifference(endsInMs, true);

  const sortedBets = useMemo(() => {
    if (!isOpen) return bets;

    return getSortedBets(bets);
  }, [bets, isOpen]);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${SHARE_URL}/?gameId=${game.id}&ref=${publicKey}`
    );
    setIcon('check');
    setTimeout(() => {
      setIcon('link');
    }, 2000);
  };

  const openDepositDialog = () => {
    setIsDepositDialogOpen(true);
  };

  const {chainId, switchChain} = useChain();
  const handleDeposit = async (e: FormEvent) => {
    e.preventDefault();

    if (!form.formState.isValid) return;

    try {
      setIsLoading(true);
      setError(null);
      if (chainId !== TARGET_CHAIN.chainId) {
        await switchChain(TARGET_CHAIN.chainId);
        addToast({
          status: 'success',
          children: 'Your network has been changed to a recommended one',
        });
      }

      if (account && provider) {
        const signer = provider?.getSigner(account);

        if (!signer) {
          throw new Error('No signer');
        }

        const {deposit} = form.getValues();
        const parsedDeposit = parseEther(deposit.toString());

        const balance = await signer.getBalance();

        if (!balance) {
          throw new Error('No balance');
        }

        if (balance.lt(parsedDeposit)) {
          form.setError('deposit', {
            type: 'custom',
            message: 'Insufficient balance',
          });
          return;
        }

        const res = await gameService.deposit(
          signer,
          game.contractAddress,
          parsedDeposit
        );

        if (!res) throw new Error('Deposit failed');
      } else if (connected && publicKey && signTransaction) {
        const {deposit} = form.getValues();
        const tokenBalance = await getTokenBalance(
          connection,
          game.currencyAddress,
          publicKey
        );
        if (tokenBalance < deposit) {
          throw new Error('Insufficient funds');
        }

        const {transaction, betId} = await gameService.depositSolInit({
          gameId: Number(game.id),
          walletAddress: publicKey.toBase58(),
          amount: deposit.toString(),
          refWallet: referral,
          currencyAddress: game.currencyAddress,
        });
        const signDepositTx = await signTransaction(transaction);
        const signedDepositTx = signDepositTx.serialize().toString('base64');
        const res = await gameService.depositSol({
          gameId: Number(game.id),
          walletAddress: publicKey.toBase58(),
          amount: deposit.toString(),
          signedTx: signedDepositTx,
          betId,
          refWallet: referral,
        });

        setStep('success');
      }
    } catch (error) {
      const ethersError = error as EthersError;
      if (ethersError.code === errors.ACTION_REJECTED) {
        setError(
          'Transaction Rejected: It appears you have declined the transaction.'
        );
        return;
      }

      if (ethersError.code === errors.NETWORK_ERROR) return;

      if ('reason' in ethersError) {
        addToast({
          status: 'error',
          children: ethersError.reason || 'Oops, something went wrong',
        });
      } else if (error instanceof Error) {
        addToast({
          status: 'error',
          children: error.message || 'Oops, something went wrong',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const form = useForm<GameDepositType>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: zodResolver(
      GameDepositScheme.refine(
        data =>
          data.deposit >= game.minDeposit && data.deposit <= game.maxDeposit,
        {
          path: ['deposit'],
          message: `Deposit must be between ${game.minDeposit} and ${game.maxDeposit}`,
        }
      )
    ),
  });

  const [connectOpen, setConnectOpen] = useState(false);
  const [copyConnect, setCopyConnect] = useState(false);
  if (connectOpen)
    return (
      <ConnectWalletDialog
        onWalletConnected={() => {
          if (referral) {
            searchParams.set('ref', referral);
          }
          searchParams.set('gameId', game.id.toString());
          setSearchParams(searchParams);
          openDepositDialog();
          setConnectOpen(false);
        }}
        onOpenChange={() => {
          searchParams.set('gameId', game.id.toString());
          setSearchParams(searchParams);
          setConnectOpen(false);
        }}
        isOpen={connectOpen}
      />
    );

  if (copyConnect)
    return (
      <ConnectWalletDialog
        onWalletConnected={() => {
          setConnectOpen(false);
          openDepositDialog();
        }}
        onOpenChange={() => {
          setCopyConnect(false);
        }}
        isOpen={copyConnect}
      />
    );

  return (
    <DialogRoot open={isOpen} onOpenChange={onOpenChange}>
      <AnimatePresence>
        {isOpen && !isDepositDialogOpen && (
          <DialogPortal
            forceMount
            variant={!isMd ? 'mobile' : undefined}
            translateY="sm:translate-y-[32px]"
          >
            <div className="sm:hidden absolute -top-[64px] left-1/2 -translate-x-1/2 z-50 w-[115px] !h-[115px] overflow-hidden rounded-[16px] border-[3px] border-white">
              <img
                src={
                  game.thumbnailUrl ||
                  'https://ponzi.market/static/media/no-image.c2584ba9cd0e540c8352.png'
                }
                className="object-cover w-full h-full"
                alt="game thumbnail"
              />
            </div>
            <div className="relative flex flex-col w-full md:w-[700px] lg:w-[753px] gap-6 px-5 pt-8  md:gap-[28px] md:px-[34px] md:pb-[24px] flex-1 xl:flex-grow-0 overflow-scroll sm:overflow-visible max-h-screen pb-12">
              <div className="hidden sm:block absolute -top-[64px] left-1/2 -translate-x-1/2 z-50 w-[115px] !h-[115px] overflow-hidden rounded-[16px] border-[3px] border-white">
                <img
                  src={
                    game.thumbnailUrl ||
                    'https://ponzi.market/static/media/no-image.c2584ba9cd0e540c8352.png'
                  }
                  className="object-cover w-full h-full"
                  alt="game thumbnail"
                />
              </div>
              <DialogClose className="absolute top-[22px] right-[17px] flex justify-center items-center w-5 h-5 outline-none md:top-[34px] md:right-[31px]">
                <Icons.Close />
              </DialogClose>
              <div className="absolute top-[20px] left-[16px] sm:top-[34px] sm:left-[34px] z-10 flex items-start gap-[20px] font-medium">
                <div>
                  <div className="text-[#A0A4AC] mb-[8px] text-[12px] leading-[16px]">
                    Fee
                  </div>
                  <div className="text-[#101520] text-[14px] leading-[21px]">
                    {game.hostFee + game.platformFee}%
                  </div>
                </div>
                <div>
                  <div className="text-[#A0A4AC] mb-[8px] text-[12px] leading-[16px]">
                    ROI
                  </div>
                  <div className="text-[#101520] text-[14px] leading-[21px]">
                    {game.roi}%
                  </div>
                </div>
              </div>
              <div>
                <h2 className="mt-[34px] text-center text-xl leading-9 text-[#101520] md:text-[1.75rem]">
                  {`${game.name} players`}
                </h2>
                <h3 className="text-[14px] leading-[16px] text-center mt-[3px]">
                  {game.bets.length} deposits
                </h3>
              </div>
              <div className="bg-[#F3F3F3] p-[18px] overflow-scroll rounded-[12px] max-h-[200px] min-h-[128px]">
                {bets.length === 0 && (
                  <p className="text-sm text-center text-[#A0A4AC]">
                    No players yet.
                  </p>
                )}
                {bets.length > 0 && (
                  <table className="w-full">
                    <thead>
                      <tr className="flex justify-between mb-8 font-medium text-xs sm:text-sm leading-5 text-[#A0A4AC]">
                        <td className="w-[120px] md:w-[200px]">Address</td>
                        <td className="w-[72px] md:w-[100px]">Bet</td>
                        <td className="w-[106px] md:w-[173px]">Progress</td>
                      </tr>
                    </thead>
                    <tbody className="grid gap-8">
                      {sortedBets.map(bet => (
                        <tr
                          key={bet.id}
                          className={clsx(
                            'grid grid-cols-[120px_72px_106px] justify-between items-start text-[#101520] md:grid-cols-[200px_100px_173px] md:items-center',
                            account === bet.user.wallet &&
                              'bg-gray-50 rounded-lg'
                          )}
                        >
                          <td
                            className="font-semibold text-xs md:text-sm leading-5"
                            title={bet.user.wallet}
                          >
                            {formatAddress(bet.user.wallet)}
                          </td>
                          <td>
                            {connected ? (
                              <SolanaValue
                                icon={coin ? coin.image : undefined}
                                value={Number(bet.amount) / 10 ** decimals}
                              />
                            ) : (
                              // only solana supported for now
                              <SolanaValue
                                icon={coin ? coin.image : undefined}
                                value={Number(bet.amount) / 10 ** decimals}
                              />
                              // <EthereumValue
                              //   value={Number(formatEther(bet.amount))}
                              // />
                            )}
                          </td>
                          <td>
                            <ProgressBar
                              current={Math.round(
                                (Number(formatEther(bet.collectedAmount)) /
                                  Number(formatEther(bet.amount))) *
                                  100
                              )}
                              total={roi + 100}
                              variant={isMd ? undefined : 'small'}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              <div
                className="flex items-center gap-[44px] justify-center flex-wrap"
                style={{rowGap: '10px'}}
              >
                <div>
                  <div className="text-center text-[#A0A4AC] text-[12px]">
                    Current jackpot
                  </div>
                  <div className="flex items-center gap-[8px]">
                    {coin ? (
                      <img
                        width={28}
                        height={28}
                        src={coin.image}
                        alt={`${coin.symbol} icon`}
                        className="rounded-full"
                      />
                    ) : (
                      <Icons.Solana />
                    )}
                    <div>
                      <div className="text-[#101520] leading-[21px] text-[14px]">
                        {formatNumberWithCommas(
                          Number(game.softPool) / 10 ** decimals
                        )}
                        {` ${coin ? coin.symbol : 'PONZI'}`}
                      </div>
                      <div className="text-[#A0A4AC] text-[12px] leading-[18px]">
                        / {formatNumberWithFixedDecimals(jackpotInUsd, 2)}$
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-center text-[#A0A4AC] text-[12px]">
                    Min. deposit
                  </div>
                  <div className="flex items-center gap-[8px]">
                    {coin ? (
                      <img
                        width={28}
                        height={28}
                        src={coin.image}
                        alt={`${coin.symbol} icon`}
                        className="rounded-full"
                      />
                    ) : (
                      <Icons.Solana />
                    )}
                    <div>
                      <div className="text-[#101520] leading-[21px] text-[14px]">
                        {formatNumberWithCommas(game.minDeposit)}
                        {` ${coin ? coin.symbol : 'PONZI'}`}
                      </div>
                      <div className="text-[#A0A4AC] text-[12px] leading-[18px]">
                        / {formatNumberWithFixedDecimals(minDepositInUsd, 2)}$
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-center text-[#A0A4AC] text-[12px]">
                    Max. deposit
                  </div>
                  <div className="flex items-center gap-[8px]">
                    {coin ? (
                      <img
                        width={28}
                        height={28}
                        src={coin.image}
                        alt={`${coin.symbol} icon`}
                        className="rounded-full"
                      />
                    ) : (
                      <Icons.Solana />
                    )}
                    <div>
                      <div className="text-[#101520] leading-[21px] text-[14px]">
                        {formatNumberWithCommas(game.maxDeposit)}
                        {` ${coin ? coin.symbol : 'PONZI'}`}
                      </div>
                      <div className="text-[#A0A4AC] text-[12px] leading-[18px]">
                        / {formatNumberWithFixedDecimals(maxDepositInUsd, 2)}$
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center gap-[14px]">
                <div className="flex justify-center gap-[14px]">
                  <button
                    className="btn-game bg-black disabled:opacity-40"
                    onClick={
                      connected
                        ? openDepositDialog
                        : () => {
                            searchParams.delete('ref');
                            searchParams.delete('gameId');
                            setSearchParams(searchParams);
                            setConnectOpen(true);
                            setIsDepositDialogOpen(false);
                          }
                    }
                    disabled={game.status === GameStatus.FINISHED}
                  >
                    {game.userGameStatus === UserGameStatus.IN_PROGRESS
                      ? 'Make another bet'
                      : 'Join the game'}
                  </button>
                  <button
                    className={`flex items-center gap-[2px] px-[16px] bg-[#F3F4F6] rounded-[43px] ${!connected ? 'opacity-80' : ''}`}
                    type="button"
                    onClick={
                      connected
                        ? handleCopy
                        : () => {
                            searchParams.delete('ref');
                            searchParams.delete('gameId');
                            setSearchParams(searchParams);
                            setCopyConnect(true);
                            setIsDepositDialogOpen(false);
                          }
                    }
                  >
                    {icon === 'link' ? (
                      <>
                        <Icons.Link />
                      </>
                    ) : (
                      <>
                        <Icons.Check />
                      </>
                    )}
                  </button>
                </div>
                <div className="text-[#A1A4AB] text-[14px] text-center">
                  Share to get <span className="text-black">25%</span> of the
                  game fee
                </div>
              </div>
              <div className="text-center text-[#A0A4AC]">
                Ends in{' '}
                <span className="text-[#101520]">
                  {hours}:{minutes}:{seconds}
                </span>
              </div>
            </div>
          </DialogPortal>
        )}
        {isOpen && isDepositDialogOpen && (
          <DialogPortal
            forceMount
            contentProps={{
              onOpenAutoFocus: e => e.preventDefault(),
              onInteractOutside: e => {
                if (isLoading) e.preventDefault();
              },
            }}
          >
            {step === 'deposit' && (
              <form
                action="POST"
                onSubmit={e => {
                  handleDeposit(e).catch(console.warn);
                }}
              >
                <FormProvider {...form}>
                  <GameDepositForm
                    game={game}
                    isLoading={isLoading}
                    error={error}
                  />
                </FormProvider>
              </form>
            )}
            {step === 'success' && <GameDepositSuccess />}
          </DialogPortal>
        )}
      </AnimatePresence>
    </DialogRoot>
  );
}
