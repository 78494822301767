import {Root as AccordionRoot} from '@radix-ui/react-accordion';
import './FAQView.css';
import {FAQAccordionItem} from './components/FAQAccordion';

export function FAQView() {
  return (
    <div className="faq">
      <h1 className="faq-heading">Frequently asked questions</h1>
      <AccordionRoot type="multiple" className="grid gap-12">
        <div>
          <h2 className="faq-subheading">Game Rules</h2>
          <div className="grid gap-3">
            <FAQAccordionItem
              value="faq-1"
              heading="When do I lose my deposit?"
            >
              You lose your deposit when the game expires before your ROI is paid off. Effectively, when there are not enough new deposits after you.
            </FAQAccordionItem>
            <FAQAccordionItem
              value="faq-2"
              heading="Will you steal my money?"
            >
              The platform handles deposits with smart contracts. We have no access to your deposits as the platform champions a non-custodial approach. There are inherent blockchain and smart contract risks that default to any decentralized application though. 
            </FAQAccordionItem>
            <FAQAccordionItem value="faq-3" heading="Joining the game">
              <p><strong>Make a Deposit:</strong> To participate, a player must make a deposit. This deposit is the player's stake in the game.</p>
              <p><strong>Sharing Mechanism:</strong> The deposit made by a player is evenly distributed among all players who joined the game before them. This means earlier players benefit from deposits made by new players.</p>
            </FAQAccordionItem>
            <FAQAccordionItem
              value="faq-4"
              heading="Earning and Withdrawing"
            >
              <p><strong>ROI:</strong> Each game has a set ROI players aim to achieve (e.g., 30%). Once a player's ROI is fulfilled (e.g., 130% of their deposit), they no longer get new deposit shares.</p>
              <p><strong>Withdrawal:</strong> Upon fulfilling their ROI, a player can withdraw their profits. Withdrawal is only possible after filling the progress bar (e.g., 130%/130%).</p>
            </FAQAccordionItem>  
            <FAQAccordionItem
              value="faq-5"
              heading="The jackpot"
            >
              <p><strong>Unfulfilled Deposits:</strong> Not all players will fulfill their ROI. If the player's progress is below the withdrawal threshold (e.g., 87%/130%) by the game's end, their deposit is considered unfulfilled and goes into the soft pool.</p>
              <p><strong>Last Player Advantage:</strong> The soft pool accumulates all unfulfilled deposits. The very last player to join the game before it closes wins the entire soft pool, regardless of their individual ROI.</p>
            </FAQAccordionItem>
            <FAQAccordionItem
              value="faq-6"
              heading="Game duration and ending"
            >
              <p><strong>Duration:</strong> Each game clock is 1-600 minutes (based on game settings that the host sets). However, each new deposit resets the timer, extending the game's duration.</p>
              <p><strong>Jackpot:</strong> The soft pool, which can grow significantly over the course of the game, is awarded to the last player to make a deposit if the game timer concludes without being reset.</p>
            </FAQAccordionItem>
            <FAQAccordionItem
              value="faq-7"
              heading="Game fees"
            >
              There's a 2-10% fee on your withdrawals (based on game settings that the host sets).
            </FAQAccordionItem>
            <FAQAccordionItem
              value="faq-8"
              heading="Hosting a game"
            >
              <p>To host a game you need to make the 1st deposit. You will set a 2-10% game fee and get half of it with every deposit, automatically.</p>
              <p>If nobody else joins, you will be the person winning the jackpot, which will consist of your initial deposit. If someone else joins after you and the game clock runs out before you fulfill your ROI, you will lose your initial deposit.</p> 
            </FAQAccordionItem>
            <FAQAccordionItem
              value="faq-9"
              heading="Tokenomics"
            >
              <p>5% of each jackpot is used to buy back and burn $ponzi, which was fairly launched on pump fun.</p>
            </FAQAccordionItem>
            <FAQAccordionItem
              value="faq-10"
              heading="Blockchain and app discrepancies"
            >
              <p><strong>Data Synchronization:</strong> In rare cases, you might notice temporary differences between the blockchain state and what's displayed in the app (up to 10 seconds). This is normal and usually resolves within a few block confirmations. Always refer to blockchain explorers for the most up-to-date transaction status.</p>
            </FAQAccordionItem>
            <FAQAccordionItem
              value="faq-11"
              heading="Referral system"
            >
              <p><strong>How It Works:</strong> Share your unique referral link with others. When they join a game using your link, you'll earn a percentage of their deposits as a referral bonus. To get a link, you must connect your wallet.</p>
              <p><strong>Rewards:</strong> Referral rewards are automatically sent to your wallet address. The exact percentage of referral rewards varies based on the game settings. If someone deposits 100 $ponzi to a 4% fee game, you will get 1 $ponzi as your referral reward.</p>
            </FAQAccordionItem>
          </div>
        </div>
      </AccordionRoot>
    </div>
  );
}
