import {useTimeDifference} from 'hooks/useTimeDifference';
import {WithdrawDialog} from 'screens/Dashboard/components/WithdrawDialog';
import {Game, GameStatus, UserGameStatus} from 'types/Game';
import {GameDepositDialog} from 'ui-kit/GameDepositDialog';
import CopyButton from 'ui-kit/CopyButton';
import {useEffect, useState} from 'react';
import './styles.css';
import {useWallet} from '@solana/wallet-adapter-react';
import {useSearchParams} from 'react-router-dom';

const SHARE_URL =
  process.env.REACT_APP_SHARE_URL || 'https://share.ponzi.market';

interface GameButtonProps {
  status: GameStatus;
  userGameStatus?: UserGameStatus;
  game: Game;
  refetchGame: () => void;
  numberOfDeposits?: number;
}

export function GameButton({
  status,
  userGameStatus,
  game,
  refetchGame,
  numberOfDeposits = 0,
}: GameButtonProps) {
  const {connected, publicKey} = useWallet();
  const [searchParams, setSearchParams] = useSearchParams();
  const endsInMs = new Date(game.finishAt).getTime(); // TODO
  // const endsInMs = new Date(Date.now() - 30 * 60 * 1000).getTime();
  const [icon, setIcon] = useState<'link' | 'check'>('link');
  const isEnded =
    endsInMs < Date.now() ||
    status === GameStatus.FINISHED ||
    userGameStatus === UserGameStatus.FAILED;

  if (
    status === GameStatus.IN_PROGRESS &&
    (endsInMs === null || endsInMs === undefined)
  ) {
    throw new Error('endsInS is required when status is IN_PROGRESS');
  }
  const {days, hours, minutes, seconds} = useTimeDifference(endsInMs, true);
  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${SHARE_URL}/?gameId=${game.id}&ref=${publicKey}`
    );
    setIcon('check');
    setTimeout(() => {
      setIcon('link');
    }, 2000);
  };

  useEffect(() => {
    if (
      !isEnded &&
      days === 0 &&
      hours === 0 &&
      +minutes === 0 &&
      +seconds === 0
    ) {
      refetchGame?.();
    }
  }, [isEnded, days, hours, minutes, seconds]);

  if (isEnded && userGameStatus === UserGameStatus.WITHDRAW_AVAILABLE) {
    return <WithdrawDialog game={game} onSuccessfulWithdraw={refetchGame} />;
  } // TODO

  if (userGameStatus === UserGameStatus.WITHDRAW_AVAILABLE && !isEnded) {
    return (
      <div className="flex flex-col justify-between w-full xl:w-auto min-[1380px]:h-[128px] max-h-full">
        <WithdrawDialog game={game} onSuccessfulWithdraw={refetchGame} />
        {numberOfDeposits && (
          <div className="pt-[0.65rem] text-sm font-medium hidden xl:block">
            {`${numberOfDeposits} ${
              numberOfDeposits === 1 ? 'deposit' : 'deposits'
            }`}
          </div>
        )}
        <span className="pt-[0.65rem] mt-auto text-sm leading-4 text-center text-[#A0A4AC] md:text-base">
          Ends in{' '}
          <span className="text-black">
            {hours}:{minutes}:{seconds}
          </span>
        </span>
      </div>
    );
  }

  if (isEnded) return null;

  return (
    <div className="flex flex-col justify-between items-center w-full xl:w-auto min-[1380px]:h-[128px] max-h-full">
      <GameDepositDialog
        game={game}
        onSuccessfulDeposit={() => {
          searchParams.set('gameId', game.id.toString());
          setSearchParams(searchParams);
          refetchGame();
        }}
      >
        <button
          className="btn-game bg-black"
          onClick={
            !connected
              ? () => {
                  searchParams.delete('gameId');
                  setSearchParams(searchParams);
                }
              : undefined
          }
        >
          {userGameStatus === UserGameStatus.IN_PROGRESS
            ? 'Make another bet'
            : 'Join the game'}
        </button>
      </GameDepositDialog>
      {numberOfDeposits !== undefined && numberOfDeposits !== null && (
        <div className="pt-[0.65rem] text-sm font-medium hidden xl:block my-auto">
          {`${numberOfDeposits} ${numberOfDeposits === 1 ? 'deposit' : 'deposits'}`}
        </div>
      )}
      <div className="items-center gap-[8px] flex xl:hidden my-[12px]">
        <CopyButton icon={icon} handleCopy={handleCopy} />
        <div className="text-[#A1A4AB] text-[14px] text-center xl:hidden">
          Share to get <span className="text-black">25%</span> of the game fee
        </div>
      </div>
      <span className="pt-[0.65rem] mt-auto text-sm leading-4 text-center text-[#A0A4AC] md:text-base">
        Ends in{' '}
        <span className="text-black">
          {hours}:{minutes}:{seconds}
        </span>
      </span>
    </div>
  );
}
