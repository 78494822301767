import {useDebounce} from '@uidotdev/usehooks';
import {useEffect, useLayoutEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useSortAndFiltersStore} from 'screens/Dashboard';
import {gameService} from 'services/games';
import {GamesHeader} from 'ui-kit/GamesHeader';
import {Icons} from 'ui-kit/Icons';
import {AllGameCard} from './components/AllGameCard';
import {useAllGamesStore} from './store';
import {useWallet} from '@solana/wallet-adapter-react';
import {useSearchParams} from 'react-router-dom';
import {GamePlayersDialog} from 'ui-kit/GamePlayersDialog';
import {Game} from 'types/Game';
import {SortOptions} from 'ui-kit/SortDialog/SortDialog';
import {getSortQueryOption} from 'utils';
import FiltersDialog, {Filters} from 'ui-kit/FiltersDialog';
import {Helmet} from 'react-helmet';

const useGameDialog = (games: Game[]) => {
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const parseGameIdFromHash = (): string | null => {
    const hash = window.location.hash;
    if (hash.includes('?')) {
      const params = new URLSearchParams(hash.split('?')[1]);
      return params.get('gameId');
    }
    return null;
  };

  const handleHashChange = async (): Promise<void> => {
    const gameId = parseGameIdFromHash();
    if (gameId) {
      const game = games.find(g => Number(g.id) === Number(gameId));
      if (game) {
        setSelectedGame(game);
        setIsDialogOpen(true);
      } else {
        try {
          await gameService.getGameInfoById(gameId).then(game => {
            setSelectedGame(game);
            setIsDialogOpen(true);
          });
        } catch (e) {
          console.error(e);
        }
      }
    } else {
      setIsDialogOpen(false);
      setSelectedGame(null);
    }
  };

  useEffect(() => {
    handleHashChange();
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [games]);

  return {
    selectedGame,
    setSelectedGame,
    isDialogOpen,
    setIsDialogOpen,
    handleHashChange,
  };
};

export function AllGamesView() {
  const {publicKey} = useWallet();
  const {
    isLoading,
    games,
    setGames,
    totalGames,
    page,
    hasMore,
    setHasMore,
    setPage,
  } = useAllGamesStore();
  const {search} = useSortAndFiltersStore();
  const debounceSearch = useDebounce(search, 1000);
  const network = 'sol';

  const [selectedSort, setSelectedSort] = useState<SortOptions>('Newest first');
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFiltersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    fromJackpot: 1,
    toJackpot: null,
    fromBet: 1,
    toBet: null,
    fromDate: null,
    toDate: null,
    fromMinute: 1,
    toMinute: 600,
    token: '',
  });

  const {selectedGame, setSelectedGame, isDialogOpen, setIsDialogOpen} =
    useGameDialog(games);

  useEffect(() => {
    if (selectedGame) {
      setIsDialogOpen(true);
    }
  }, [selectedGame, setIsDialogOpen]);

  // Update this useEffect to automatically open the dialog when a game is selected
  useEffect(() => {
    if (selectedGame) {
      setIsDialogOpen(true);
      // Optionally update URL params
      // searchParams.set('gameId', selectedGame.id);
      // setSearchParams(searchParams);
    }
  }, [selectedGame, setIsDialogOpen, searchParams, setSearchParams]);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedGame(null);
    searchParams.delete('gameId');
    searchParams.delete('ref');
    setSearchParams(searchParams);
  };

  useLayoutEffect(() => {
    if (search !== '') return;

    setPage(0);
    setGames([]);
    setHasMore(true);
    gameService
      .getAllGames({
        network,
        addToExisting: true,
        search: debounceSearch,
        from: publicKey?.toString(),
        sort: getSortQueryOption(selectedSort),
        ...(filters.fromDate && {
          fromDate: filters.fromDate,
        }),
        ...(filters.toDate && {
          toDate: filters.toDate,
        }),
        fromJackpot: filters.fromJackpot,
        ...(filters.toJackpot && {
          toJackpot: filters.toJackpot,
        }),
        fromBet: filters.fromBet,
        ...(filters.toBet && {
          toBet: filters.toBet,
        }),
        ...(filters.fromMinute && {
          fromMinute: filters.fromMinute,
        }),
        ...(filters.toMinute && {
          toMinute: filters.toMinute,
        }),
      })
      .then(res => {
        setHasMore(res.length > 0);
        setGames(res);
      })
      .catch(err => {
        console.warn(err);
      });
  }, [
    setPage,
    network,
    filters,
    selectedSort,
    search,
    publicKey,
    debounceSearch,
  ]);

  const handleNext = () => {
    setPage(page + 1);
    gameService
      .getAllGames({
        search: debounceSearch,
        addToExisting: true,
        sort: getSortQueryOption(selectedSort),
        network,
        from: publicKey?.toString(),
        ...(filters.fromDate && {
          fromDate: filters.fromDate,
        }),
        ...(filters.toDate && {
          toDate: filters.toDate,
        }),
        fromJackpot: filters.fromJackpot,
        ...(filters.toJackpot && {
          toJackpot: filters.toJackpot,
        }),
        fromBet: filters.fromBet,
        ...(filters.toBet && {
          toBet: filters.toBet,
        }),
        fromMinute: filters.fromMinute,
        ...(filters.toMinute && {
          toMinute: filters.toMinute,
        }),
      })
      .then(res => setHasMore(res.length > 0))
      .catch(err => {
        console.warn(err);
      });
  };

  return (
    <>
      <Helmet>
        <title>ponzi.market</title>
      </Helmet>
      <GamesHeader
        setIsModalOpen={setFiltersOpen}
        selectedSort={selectedSort}
        setSelectedSort={setSelectedSort}
        heading="All Games"
        gamesLength={totalGames}
        isLoading={isLoading}
      />
      {isLoading && (
        <div className="flex items-center justify-center w-full my-8 text-[#A8A8A8]">
          <Icons.Spinner className="animate-spin" color="#A8A8A8" />
        </div>
      )}
      <div className="grid grid-cols-1 auto-rows-fr gap-6 pb-6 min-[1300px]:auto-rows-[11rem]">
        {!isLoading && games.length === 0 && (
          <div className="flex items-center justify-center w-full text-[#A8A8A8]">
            No games
          </div>
        )}
        {!isLoading && games.length > 0 && (
          <InfiniteScroll
            next={handleNext}
            hasMore={hasMore}
            scrollableTarget="scroll-container"
            loader={
              <div className="flex items-center justify-center w-full my-8 text-[#A8A8A8]">
                <Icons.Spinner className="animate-spin" color="#A8A8A8" />
              </div>
            }
            dataLength={games.length}
            className="grid grid-cols-1 auto-rows-fr gap-6 pb-6 min-[1380px]:auto-rows-[11rem]"
          >
            {games.map((game, i) => (
              <AllGameCard
                key={i}
                game={game}
                setGame={g => setSelectedGame(g)}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>

      {isFiltersOpen && (
        <FiltersDialog
          page="all-games"
          isOpen={isFiltersOpen}
          onOpenChange={setFiltersOpen}
          setFilters={setFilters}
        />
      )}

      {selectedGame && isDialogOpen && (
        <GamePlayersDialog
          isOpen={isDialogOpen}
          onOpenChange={handleDialogClose}
          game={selectedGame}
          setIsOpen={setIsDialogOpen}
        />
      )}
    </>
  );
}
