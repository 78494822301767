import {BigNumber, BigNumberish} from 'ethers';

export enum GameStatus {
  AWAITING_DEPOSIT = 'AWAITING_DEPOSIT',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export enum UserGameStatus {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  WITHDRAW_AVAILABLE = 'WITHDRAW_AVAILABLE',
  FINISHED = 'FINISHED',
}

export interface Game {
  id: string;
  name: string;
  thumbnailUrl: string;
  status: GameStatus;
  userGameStatus?: UserGameStatus;
  roi: number;
  minDeposit: number;
  maxDeposit: number;
  hostFee: number;
  platformFee: number;
  softPool: string;
  hardPool: string;
  host: GameHost;
  bets: Bet[];
  membersCount: number;
  hostId: string;
  createdAt: string;
  contractAddress: string;
  finishAt: string;
  currencyAddress: string;
}

export interface GameHost {
  id: string;
  wallet: string;
  createdAt: string;
}

export enum BetStatus {
  PENDING = 'PENDING',
  WITHDRAW_AVAILABLE = 'WITHDRAW_AVAILABLE',
  JACKPOT_WITHDRAW_AVAILABLE = 'JACKPORT_WITHDRAW_AVAILABLE',
  WITHDRAWED = 'WITHDRAWED',
}

export interface Bet {
  id: string;
  amount: string;
  collectedAmount: string;
  amountForWithdraw: string;
  status: BetStatus;
  gameId: string;
  user: {
    id: string;
    wallet: string;
    createdAt: string;
  };
  createdAt: Date;
  betId: number;
}

export enum SortQueryOptions {
  NEWEST = 'newest',
  OLDEST = 'oldest',
  MOST_POPULAR = 'most_popular',
  LEAST_POPULAR = 'least_popular',
}

export interface GameRequestOptions {
  page?: number;
  limit?: number;
  addToExisting?: boolean;
  search?: string;
  network?: 'sol' | 'arb';
  from?: string;
  sort?: SortQueryOptions;

  // filters
  fromDate?: Date;
  toDate?: Date;
  fromJackpot?: number;
  toJackpot?: number;
  fromBet?: number;
  toBet?: number;
  fromMinute?: number;
  toMinute?: number;
}

export interface CreateGameParams {
  payableAmount: BigNumberish;
  name: string;
  file: string;
  fee: BigNumber;
  maxDeposit: BigNumber;
  minDeposit: BigNumber;
  roi: BigNumber;
}

export interface CreateGameParamsSolInit {
  name: string;
  thumbnailUrl: string;
  fee: number;
  maxDeposit: number;
  minDeposit: number;
  roi: number;
  duration: number; // duration in minutes
  coinAddress: string;
}

export interface CreateGameParamsSol {
  signedTx: string;
  gameInfo: CreateGameParamsSolInit;
  gameId: number;
}

export interface GamesResponse {
  total: number;
  data: Game[];
}

export interface WithdrawResponse {
  signarute: string;
  data: {
    amount: string;
    recipient: string;
    nonce: number;
  };
}
