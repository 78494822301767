import {useIsMedium} from 'lib/useBreakpoints';
import {Icons} from './Icons';
import {formatNumberWithCommas} from 'lib/formatNum';

export function SolanaValue({
  value,
  icon,
  symbol,
}: {
  value: number;
  icon?: string;
  symbol?: string;
}) {
  const isMd = useIsMedium();

  return (
    <div className="flex items-center gap-2">
      <div className="flex items-center justify-center w-4 h-4 rounded-full bg-[#F4F4F4] md:w-6 md:h-6">
        {icon ? (
          <img
            src={icon}
            alt="Solana"
            width={20}
            height={20}
            className="rounded-full"
          />
        ) : (
          <Icons.Solana width={!isMd ? 7 : 10} height={!isMd ? 10 : 16} />
        )}
      </div>
      <span className="text-xs leading-normal md:text-sm">
        {formatNumberWithCommas(value)}
        {` ${symbol ?? 'PONZI'}`}
      </span>
    </div>
  );
}
