import {z} from 'zod';
import {ACCEPTED_MIME_TYPES, MAX_FILE_SIZE} from './constants';
import {
  MAX_DEPOSIT,
  MAX_DEPOSIT_SOL,
  MIN_DEPOSIT,
  MIN_DEPOSIT_SOL,
} from '../../../../constants';

export const NewGameFormScheme = (network: 'sol' | 'arb') =>
  z.object({
    step: z.number().min(0).max(5),
    isLoading: z.boolean(),

    isRulesAccepted: z.boolean(),

    name: z.string().min(1, 'Name is required.'),
    roi: z.coerce.number().min(1).max(100),
    duration: z.coerce.number().min(1).max(600),
    minDeposit: z.coerce
      .number({
        invalid_type_error: 'Min deposit should be a number.',
      })
      .min(network === 'arb' ? MIN_DEPOSIT : MIN_DEPOSIT_SOL)
      .max(network === 'arb' ? MAX_DEPOSIT : MAX_DEPOSIT_SOL),
    maxDeposit: z.coerce
      .number({
        invalid_type_error: 'Max deposit should be a number.',
      })
      .min(network === 'arb' ? MIN_DEPOSIT : MIN_DEPOSIT_SOL)
      .max(network === 'arb' ? MAX_DEPOSIT : MAX_DEPOSIT_SOL),
    fee: z.coerce.number().min(2).max(10).positive(),
    thumbnail: z
      .instanceof(FileList)
      .refine(fileList => fileList.length === 1, 'Only one file is allowed.')
      .refine(
        fileList => fileList[0].size < MAX_FILE_SIZE,
        `Image too large. Max size is ${MAX_FILE_SIZE / 1024 / 1024} MB.`
      )
      .refine(
        fileList => ACCEPTED_MIME_TYPES.includes(fileList[0].type),
        'Only jpeg, png, heic are allowed.'
      )
      .nullish(),

    firstDeposit: z.coerce.number({
      invalid_type_error: 'First deposit is required.',
    }),
    coinAddress: z.string(),
    coinIcon: z.string(),
    coinSymbol: z.string(),
  });

export type NewGameFormType = z.infer<ReturnType<typeof NewGameFormScheme>>;

export const refiners = {
  minMaxDeposit: (data: Pick<NewGameFormType, 'minDeposit' | 'maxDeposit'>) => {
    return data.minDeposit < data.maxDeposit;
  },
  firstDeposit: (
    data: Pick<NewGameFormType, 'firstDeposit' | 'minDeposit' | 'maxDeposit'>
  ) => {
    return (
      data.firstDeposit >= (data.minDeposit || MIN_DEPOSIT) &&
      data.firstDeposit <= (data.maxDeposit || MAX_DEPOSIT)
    );
  },
};

export const NewGameFormSchemeFull = (network: 'sol' | 'arb') =>
  NewGameFormScheme(network)
    .extend({})
    .superRefine((data, ctx) => {
      if (!refiners.minMaxDeposit(data)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Max deposit should be greater than min deposit.',
          path: ['maxDeposit'],
        });
      }

      if (!refiners.firstDeposit(data)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Deposit must be between ${
            data.minDeposit || network === 'arb' ? MIN_DEPOSIT : MIN_DEPOSIT_SOL
          } and ${data.maxDeposit || network === 'arb' ? MAX_DEPOSIT : MAX_DEPOSIT_SOL}.`,
          path: ['firstDeposit'],
        });
      }
    });
