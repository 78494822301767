import {DialogClose} from '@radix-ui/react-dialog';
import {Controller, useFormContext} from 'react-hook-form';
import {Game} from 'types/Game';
import {FieldEthereumIcon} from 'ui-kit/FieldEthereumIcon';
import {FormCheckbox} from 'ui-kit/FormCheckbox';
import {FormField} from 'ui-kit/FormField';
import {FormMessage} from 'ui-kit/FormMessage';
import {GameDepositType} from './scheme';
import {Link} from 'react-router-dom';
import {useWallet} from '@solana/wallet-adapter-react';
import {FieldSolanaIcon} from '../FieldSolanaIcon';
import {useEffect, useState} from 'react';
import {Coin} from 'types';
import {getCoin} from 'utils/game';

interface GameDepositFormProps {
  game: Game;
  isLoading: boolean;
  error: string | null;
}

export function GameDepositForm({
  game,
  isLoading,
  error,
}: GameDepositFormProps) {
  const formCtx = useFormContext<GameDepositType>();
  const [coin, setCoin] = useState<Coin | undefined>(undefined);
  useEffect(() => {
    const retreiveIcon = async () => {
      const c = await getCoin(game.currencyAddress);
      if (c) {
        setCoin(c);
      }
    };
    retreiveIcon().catch(console.error);
  }, []);
  const {connected} = useWallet();
  const isInvalid = !formCtx.formState.isValid;
  const isNotAccepted = !formCtx.watch('isRulesAccepted');

  return (
    <div className="flex flex-col gap-8 px-5 py-8 md:px-[3.75rem] md:pt-[3.75rem] md:pb-[3.125rem] md:gap-[3.125rem]">
      <h2 className="text-center text-xl leading-6 text-[#101520] md:text-[1.75rem] md:leading-8">
        Add your deposit to join the
        <br />
        <span className="max-w-[488px] block truncate">{game.name}</span>
      </h2>
      <div className="flex flex-col gap-4 w-full max-w-[488px]">
        <div className="grid gap-2">
          <FormField
            type="number"
            label="Your deposit"
            placeholder={`${game.minDeposit} ${coin ? coin.symbol : 'PONZI'}`}
            leftIcon={
              coin ? (
                <img
                  src={coin.image}
                  alt="Currency icon"
                  width={20}
                  height={20}
                  className="rounded-full"
                />
              ) : (
                <FieldSolanaIcon />
              )
            }
            rightIcon={
              <span className="text-xs text-[#A8A8A8]">
                Max.&nbsp;{game.maxDeposit}&nbsp;{coin ? coin.symbol : 'PONZI'}
              </span>
            }
            min={game.minDeposit}
            max={game.maxDeposit}
            step={connected ? 1 : 0.001}
            {...formCtx.register('deposit', {
              valueAsNumber: true,
            })}
            disabled={isLoading}
          />
          <FormMessage name="deposit" />
        </div>
        <div className="flex items-center gap-2.5 p-5 rounded-2xl border border-[#DDF2DB] bg-[#EBFDE9] text-[#536751] text-sm">
          Your deposit is split equally between players who haven't hit ROI yet. You get paid from new players until you hit your ROI. Then you can withdraw. Not enough players join = you lose your deposit, but if you're last = win the jackpot.
        </div>
        <Controller
          name="isRulesAccepted"
          control={formCtx.control}
          render={({field}) => (
            <FormCheckbox
              label="I confirm that I have read the Terms of Service and agree to them"
              className="text-[#8C8C8C]"
              {...field}
            />
          )}
        />
        {error && (
          <div className="flex items-center text-red-600 text-xs sm:text-sm">
            {error}
          </div>
        )}
      </div>
      <div className="flex flex-col justify-center items-center gap-6">
        <button
          type="submit"
          className="btn w-full sm:w-[279px]"
          disabled={isInvalid || isLoading || isNotAccepted}
        >
          {isLoading ? 'Loading...' : 'Make deposit and join game'}
        </button>
        <Link to="/terms" target="_blank" className="text-sm">
          Terms of Service
        </Link>
      </div>
    </div>
  );
}
