import {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {Icons} from 'ui-kit/Icons';
import {readImage} from 'utils';
import {NewGameFormType} from './scheme';
import {NewGameCard} from './NewGameCard';
import {NewGameStep} from './NewGameStep1';

const noImage = require('assets/no-image.png') as string;

export function NewGameStep3() {
  const formCtx = useFormContext<NewGameFormType>();
  const [previewSrc, setPreviewSrc] = useState<string>();

  const {name, roi, minDeposit, maxDeposit, thumbnail} = formCtx.getValues();

  useEffect(() => {
    if (!thumbnail) return;
    readImage(thumbnail[0])
      .then(src => setPreviewSrc(src as string))
      .catch(() => setPreviewSrc(noImage));
  }, [thumbnail]);

  const handlePrev = () => {
    formCtx.setValue('step', 2);
  };

  const handleNext = () => {
    formCtx.setValue('step', 4);
  };

  return (
    <NewGameStep step={3} steps={4} title="Confirm game details">
      <div className="flex flex-col gap-8 w-[calc(100vw-5rem)] sm:w-[488px]">
        <NewGameCard
          imgSrc={previewSrc ?? noImage}
          name={name}
          roi={roi}
          minDeposit={minDeposit}
          maxDeposit={maxDeposit}
          icon={formCtx.getValues('coinIcon')}
          symbol={formCtx.getValues('coinSymbol')}
        />
      </div>
      <div className="flex flex-col justify-center items-center gap-6">
        <button className="btn w-[220px]" onClick={handleNext}>
          Confirm
        </button>
        <button className="flex items-center gap-4" onClick={handlePrev}>
          <span className="rotate-180">
            <Icons.ArrowRight color="black" />
          </span>
          Previous step
        </button>
      </div>
    </NewGameStep>
  );
}
