import {DialogTrigger} from '@radix-ui/react-dialog';
import {useWeb3React} from '@web3-react/core';
import {AxiosError} from 'axios';
import {errors} from 'ethers';
import {formatEther} from 'ethers/lib/utils';
import {AnimatePresence} from 'framer-motion';
import {useChain} from 'hooks/useChain';
import {TARGET_CHAIN} from 'lib/web3-react/constants/chains';
import {FormEvent, useEffect, useMemo, useState} from 'react';
import {useDashboardStore} from 'screens/Dashboard/store/store';
import {gameService} from 'services/games';
import {Coin, EthersError} from 'types';
import {BetStatus, Game} from 'types/Game';
import {DialogPortal, DialogRoot} from 'ui-kit/Dialog';
import {
  getBetForWithdraw,
  getCoin,
  getJackpotAmountToWithdraw,
  getTokenDecimals,
} from 'utils/game';
import {WithdrawForm} from './WithdrawForm';
import {WithdrawSuccess} from './WithdrawSuccess';
import {Step} from './types';
import {useWallet} from '@solana/wallet-adapter-react';
// import {connection} from 'constants';

interface WithdrawDialogProps {
  game: Game;
  onSuccessfulWithdraw?: () => void;
}

export function WithdrawDialog({
  game,
  onSuccessfulWithdraw,
}: WithdrawDialogProps) {
  const {account, provider} = useWeb3React();
  const {publicKey, connected, signTransaction} = useWallet();
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState<Step>('withdraw');
  const {bets} = game;
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const {chainId, switchChain} = useChain();
  const addToast = useDashboardStore(s => s.addToast);

  let address: string | undefined = undefined;
  if (publicKey) {
    address = publicKey.toString();
  } else if (account) {
    address = account;
  }

  const network = connected ? 'sol' : 'arb';

  const withdrawBet = useMemo(() => {
    return address ? getBetForWithdraw(bets, address) : undefined;
  }, [bets, account, publicKey]);

  const [amount, setAmount] = useState(0);
  const [jackpotAmount, setJackpotAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [decimals, setDecimals] = useState<number>(6);
  const [coin, setCoin] = useState<Coin | undefined>(undefined);
  useEffect(() => {
    const retreiveIcon = async () => {
      const c = await getCoin(game.currencyAddress);
      if (c) {
        setCoin(c);
      }
      const d = await getTokenDecimals(game.currencyAddress);
      if (d) setDecimals(d);
    };
    retreiveIcon().catch(console.error);
  }, []);

  useEffect(() => {
    const hostFee = game.hostFee / 100 || 0;
    const platformFee = game.platformFee / 100 || 0;

    const amount = connected
      ? Number(withdrawBet?.amountForWithdraw) / 10 ** decimals
      : +formatEther(withdrawBet?.amountForWithdraw || 0);
    setAmount(amount - amount * (hostFee + platformFee));

    const jckptAmount = getJackpotAmountToWithdraw(bets);
    setJackpotAmount(jckptAmount);
  }, [withdrawBet, game.hostFee, game.platformFee]);

  useEffect(() => {
    if (!address) return;

    if (
      withdrawBet?.status === BetStatus.JACKPOT_WITHDRAW_AVAILABLE &&
      isOpen
    ) {
      setIsLoading(true);
      const hostFee = game.hostFee / 100 || 0;
      const platformFee = game.platformFee / 100 || 0;
      gameService
        .getWithdrawBet(game.id, withdrawBet.id, address || '', true, network)
        .then(data => {
          if (data) {
            const amount = connected
              ? Number(data.data.amount) / 10 ** decimals
              : +formatEther(data.data.amount || 0);
            setAmount(amount - amount * (hostFee + platformFee));
          }
        })
        .catch(console.warn)
        .finally(() => setIsLoading(false));
    }
  }, [
    connected,
    address,
    game.hostFee,
    game.id,
    game.platformFee,
    isOpen,
    withdrawBet?.id,
    withdrawBet?.status,
  ]);

  const handleDialogClose = (isOpen: boolean) => {
    setIsOpen(isOpen);
    if (isOpen) return;
    if (step === 'success') onSuccessfulWithdraw?.();
    setStep('withdraw');
    setIsWithdrawing(false);
    setError(null);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsWithdrawing(true);
    setError(null);

    try {
      if (!withdrawBet) {
        throw new Error('Bet account missing');
      }
      if (!address) {
        throw new Error('Wallet address missing');
      }
      if (network === 'arb') {
        if (!chainId) return;

        if (chainId !== TARGET_CHAIN.chainId) {
          await switchChain(TARGET_CHAIN.chainId);
          return;
        }

        const signer = provider?.getSigner(address);

        if (!signer) {
          throw new Error('No signer');
        }

        await gameService.withdraw(game, withdrawBet, address, signer, network);
      } else {
        if (!signTransaction) {
          throw new Error('Wallet not connected');
        }
        const tx = await gameService.withdrawSolInit(
          game,
          withdrawBet,
          address,
          withdrawBet.status === BetStatus.JACKPOT_WITHDRAW_AVAILABLE
            ? jackpotAmount
            : amount
        );
        const signedTx = await signTransaction(tx);
        const signedTxStr = signedTx.serialize().toString('base64');

        const {success} = await gameService.withdrawSol({
          gameId: Number(game.id),
          walletAddress: address,
          betId: withdrawBet.betId,
          signedTx: signedTxStr,
        });
        // if (success) {
        setStep('success');
        // }
      }
    } catch (error) {
      const ethersError = error as EthersError;
      if (ethersError.code === errors.ACTION_REJECTED) {
        setError(
          'Transaction Rejected: It appears you have declined the transaction.'
        );
        return;
      }
      if (ethersError.code === errors.NETWORK_ERROR) return;
      if (error instanceof AxiosError) {
        addToast({
          status: 'error',
          children: error.response?.data?.message || error.message,
        });
      } else if ('reason' in ethersError) {
        addToast({
          status: 'error',
          children: ethersError.reason || 'Oops, something went wrong',
        });
      } else if (error instanceof Error) {
        addToast({
          status: 'error',
          children: error.message || 'Oops, something went wrong',
        });
      }
    } finally {
      setIsWithdrawing(false);
    }
  };

  return (
    <DialogRoot open={isOpen} onOpenChange={handleDialogClose}>
      <DialogTrigger asChild>
        <button className="btn-game bg-black">
          {withdrawBet?.status === BetStatus.JACKPOT_WITHDRAW_AVAILABLE
            ? 'Withdraw Jackpot'
            : 'Withdraw'}
        </button>
      </DialogTrigger>
      <AnimatePresence>
        {isOpen && (
          <DialogPortal
            forceMount
            contentProps={{
              onInteractOutside: e => {
                if (isWithdrawing) e.preventDefault();
              },
            }}
          >
            {step === 'withdraw' && (
              <form action="POST" onSubmit={e => handleSubmit(e).catch}>
                <WithdrawForm
                  icon={coin ? coin.image : undefined}
                  currencyAddress={game.currencyAddress}
                  isLoading={isLoading}
                  amount={
                    withdrawBet?.status === BetStatus.JACKPOT_WITHDRAW_AVAILABLE
                      ? jackpotAmount / 10 ** decimals -
                        (jackpotAmount / 10 ** decimals) *
                          ((game.hostFee / 100 || 0) +
                            (game.platformFee / 100 || 0))
                      : amount
                  }
                  isWithdrawing={isWithdrawing}
                  error={error}
                />
              </form>
            )}
            {step === 'success' && <WithdrawSuccess />}
          </DialogPortal>
        )}
      </AnimatePresence>
    </DialogRoot>
  );
}
