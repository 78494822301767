import {useSortAndFiltersStore} from 'screens/Dashboard';
import {Icons} from 'screens/Dashboard/components/Icons';
import SortDialog, {SortOptions} from 'ui-kit/SortDialog/SortDialog';
import {useState} from 'react';
import './styles.css';
import {AnimatePresence, motion} from 'framer-motion';

interface GamesHeaderProps {
  heading: string;
  gamesLength: number;
  isLoading?: boolean;
  selectedSort: SortOptions;
  setIsModalOpen: (v: boolean) => void;
  setSelectedSort: (sort: SortOptions) => void;
}

export function GamesHeader({
  heading,
  gamesLength,
  isLoading = false,
  selectedSort,
  setIsModalOpen,
  setSelectedSort,
}: GamesHeaderProps) {
  const {search, setSearch} = useSortAndFiltersStore();
  const [sortOpen, setSortOpen] = useState(false);

  return (
    <div className="games-header">
      <div className="flex items-end h-8 gap-3">
        <h1 className="games-header-heading">{heading}</h1>
        {!isLoading && (
          <span className="flex justify-center items-center px-3 py-1 rounded-lg bg-white text-xs sm:text-sm">
            {gamesLength}
          </span>
        )}
      </div>
      <div className="flex items-baseline w-full gap-2 sm:gap-8 sm:w-auto">
        <div className="relative hidden sm:flex items-center gap-[16px]">
          <div className="text-[#A0A4AC]">Sort by:</div>
          <button
            type="button"
            className="text-[16px] font-medium flex items-center gap-[12px]"
            onClick={() => setSortOpen(!sortOpen)}
          >
            {selectedSort}
            <motion.div
              style={{
                height: '5px',
                aspectRatio: '2',
                clipPath: 'polygon(50% 100%,100% 0,0 0)',
                background: '#000',
                rotate: 180,
              }}
              animate={{rotate: sortOpen ? 0 : 180}}
              transition={{
                duration: 0.15,
                ease: 'easeInOut',
              }}
            />
          </button>
          {sortOpen && (
            <AnimatePresence>
              <SortDialog
                position="top-8 -right-2"
                setIsOpen={setSortOpen}
                selectedSort={selectedSort}
                setSelectedSort={sort => setSelectedSort(sort as SortOptions)}
              />
            </AnimatePresence>
          )}
          <div className="relative">
            <button
              className="dashboard-header-sort-button flex items-center gap-[10px] bg-white py-[6px] px-[12px] rounded-[8px]"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <Icons.Sort />
              filters
            </button>
          </div>
        </div>
        <div className="grow flex items-center h-10 gap-2.5 px-4 bg-white rounded-full sm:hidden">
          <Icons.Search />
          <input
            type="text"
            placeholder="Search"
            className="dashboard-header-search-input h-10"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>
        <div className="relative block sm:hidden flex-shrink-0">
          <button
            className="dashboard-header-sort-button bg-white p-[11px] rounded-full mr-[8px]"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <Icons.Filter />
          </button>
          <button
            className="dashboard-header-sort-button bg-white p-[11px] rounded-full"
            onClick={() => setSortOpen(!sortOpen)}
          >
            <Icons.Sort />
          </button>
          {sortOpen && (
            <AnimatePresence>
              <SortDialog
                position="top-12 right-0"
                setIsOpen={setSortOpen}
                selectedSort={selectedSort}
                setSelectedSort={sort => setSelectedSort(sort as SortOptions)}
              />
            </AnimatePresence>
          )}
        </div>
      </div>
    </div>
  );
}
