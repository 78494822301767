import {useWallet} from '@solana/wallet-adapter-react';
import {useWeb3React} from '@web3-react/core';
import {useState} from 'react';

export const useSignMessage = () => {
  const [isSigning, setIsSigning] = useState(false);
  const {provider, account} = useWeb3React();
  const {wallet, signMessage: solanaMSG} = useWallet();

  const signMessage = async (message: string): Promise<string | Uint8Array> => {
    if (wallet && solanaMSG) {
      setIsSigning(true);
      const encodedMessage = new TextEncoder().encode(message);
      const data = await solanaMSG(encodedMessage);
      setIsSigning(false);
      return data;
    }

    if (account && provider) {
      setIsSigning(true);
      const data = await provider.getSigner(account).signMessage(message);
      setIsSigning(false);
      return data;
    }

    throw new Error('No account or provider found');
  };

  return {
    isSigning,
    signMessage,
  };
};
