import {useWeb3React} from '@web3-react/core';
import {ethers} from 'ethers';
import {parseEther, parseUnits} from 'ethers/lib/utils';
import {useChain} from 'hooks/useChain';
import {TARGET_CHAIN} from 'lib/web3-react/constants/chains';
import {useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {gameService} from 'services/games';
import {EthersError} from 'types';
import {FieldEthereumIcon} from 'ui-kit/FieldEthereumIcon';
import {FormField} from 'ui-kit/FormField';
import {FormMessage} from 'ui-kit/FormMessage';
import {Icons} from 'ui-kit/Icons';
import {NewGameStep} from './NewGameStep1';
import {NewGameFormScheme, NewGameFormType, refiners} from './scheme';
import {useWallet} from '@solana/wallet-adapter-react';
import {FieldSolanaIcon} from 'ui-kit/FieldSolanaIcon';
import {getTokenBalance} from 'utils/web3';
import {connection, TOKEN_ADDRESS} from '../../../../constants';

export function NewGameStep4() {
  const [disabledBtn, setDisabledBtn] = useState(true);
  const {connected, publicKey, signTransaction} = useWallet();
  const {isActive, provider, account} = useWeb3React();
  const formCtx = useFormContext<NewGameFormType>();
  const {minDeposit, maxDeposit} = formCtx.getValues();
  const firstDeposit = formCtx.watch('firstDeposit');
  const isLoading = formCtx.watch('isLoading');

  const calcMinDeposit = useMemo(() => {
    return Math.ceil(
      (formCtx.getValues('minDeposit') * 200) / formCtx.getValues('roi')
    );
  }, [formCtx]);

  useEffect(() => {
    if (firstDeposit < calcMinDeposit) {
      setDisabledBtn(true);
    } else if (connected || isActive) {
      setDisabledBtn(false);
    }
  }, [firstDeposit, calcMinDeposit, connected, isActive]);

  const isInvalid = useMemo(
    () =>
      NewGameFormScheme(connected ? 'sol' : 'arb')
        .pick({
          firstDeposit: true,
          minDeposit: true,
          maxDeposit: true,
        })
        .refine(refiners.firstDeposit)
        .safeParse({
          firstDeposit,
          minDeposit,
          maxDeposit,
        }).success === false,
    [firstDeposit, minDeposit, maxDeposit]
  );

  const {chainId, switchChain} = useChain();

  const handlePrev = () => {
    formCtx.resetField('firstDeposit');
    formCtx.setValue('step', 3);
    formCtx.clearErrors('root');
  };

  const handleEth = async (
    name: string,
    thumbnailUrl: string,
    fee: string,
    roi: string,
    minDeposit: string,
    maxDeposit: string
  ) => {
    if (!account) return;
    const signer = provider?.getSigner(account);
    if (!signer) {
      throw new Error('No signer');
    }

    const balance = await provider?.getBalance(account);
    if (!balance) {
      throw new Error('No balance');
    }

    const deposit = parseEther(firstDeposit.toString());
    if (balance.lt(deposit)) {
      formCtx.setError('firstDeposit', {
        type: 'custom',
        message: 'Insufficient balance',
      });
      throw new Error('Insufficient balance');
    }

    await gameService.createGame(signer, {
      payableAmount: deposit,
      name,
      file: thumbnailUrl,
      fee: parseUnits(fee, 2),
      minDeposit: parseEther(minDeposit.toString()),
      maxDeposit: parseEther(maxDeposit.toString()),
      roi: parseUnits(roi, 2),
    });
  };

  const handleNext = async () => {
    let errMsg = '';
    try {
      formCtx.setValue('isLoading', true);
      formCtx.clearErrors('root');
      if (chainId !== TARGET_CHAIN.chainId) {
        await switchChain(TARGET_CHAIN.chainId);
      }
      const {
        thumbnail,
        firstDeposit,
        fee,
        minDeposit,
        maxDeposit,
        roi,
        name,
        duration,
        coinAddress,
      } = formCtx.getValues();

      let thumbnailUrl = '';
      if (thumbnail) {
        const res = await gameService.uploadFile(thumbnail);
        if (res) thumbnailUrl = res;
      }

      if (account && provider) {
        await handleEth(
          name,
          thumbnailUrl,
          fee.toString(),
          roi.toString(),
          minDeposit.toString(),
          maxDeposit.toString()
        );
        formCtx.setValue('step', 5);
      } else if (connected && publicKey && signTransaction) {
        // signTransaction is signer (in useWallet hook)

        const tokenBalance = await getTokenBalance(
          connection,
          coinAddress,
          publicKey
        );
        if (tokenBalance < firstDeposit) {
          errMsg = 'Insufficient funds';
          throw new Error(errMsg);
        }

        const createGametx = await gameService.createGameSolInit(
          {
            name,
            thumbnailUrl,
            fee,
            minDeposit,
            maxDeposit,
            roi,
            duration,
            coinAddress,
          },
          publicKey.toBase58()
        );

        const signCreateGameTx = await signTransaction(
          createGametx.transaction
        );
        const signedCreateGameTx = signCreateGameTx
          .serialize()
          .toString('base64');

        const newGame = await gameService.createGameSol({
          gameInfo: {
            name,
            thumbnailUrl,
            fee,
            minDeposit,
            maxDeposit,
            roi,
            duration,
            coinAddress,
          },
          signedTx: signedCreateGameTx,
          gameId: createGametx.gameId,
        });

        const {transaction, betId} = await gameService.depositSolInit({
          gameId: Number(newGame.id),
          walletAddress: publicKey.toBase58(),
          amount: firstDeposit.toString(),
          currencyAddress: formCtx.getValues('coinAddress'),
        });
        const signDepositTx = await signTransaction(transaction);
        const signedDepositTx = signDepositTx.serialize().toString('base64');
        await gameService.depositSol({
          gameId: Number(newGame.id),
          walletAddress: publicKey.toBase58(),
          amount: firstDeposit.toString(),
          signedTx: signedDepositTx,
          betId,
        });

        formCtx.setValue('step', 5);
      } else {
        errMsg = 'Wallet not connected';
        throw new Error(errMsg);
      }
    } catch (error) {
      if ((error as EthersError).code === ethers.errors.ACTION_REJECTED) {
        formCtx.setError('root', {
          type: 'custom',
          message:
            'Transaction Rejected: It appears you have declined the transaction.',
        });
        return;
      }

      console.warn(error);
      formCtx.setError('root', {
        type: 'custom',
        message: errMsg || 'Something went wrong',
      });
    } finally {
      formCtx.setValue('isLoading', false);
    }
  };

  return (
    <NewGameStep step={4} steps={4} title="Add the initial deposit">
      <div className="flex flex-col gap-4 w-[calc(100vw-5rem)] sm:w-[488px]">
        <div className="grid gap-2">
          <FormField
            type="number"
            label="First deposit"
            placeholder={`${calcMinDeposit} ${formCtx.getValues('coinSymbol') ?? 'PONZI'}`}
            leftIcon={
              formCtx.getValues('coinIcon') ? (
                <img
                  width={20}
                  height={20}
                  src={formCtx.getValues('coinIcon')}
                  alt="Coin icon"
                  className="rounded-full"
                />
              ) : (
                <FieldSolanaIcon />
              )
            }
            rightIcon={
              <span className="text-xs text-[#A8A8A8]">
                Max.&nbsp;{maxDeposit}&nbsp;
                {formCtx.getValues('coinSymbol') ?? 'PONZI'}
              </span>
            }
            {...formCtx.register('firstDeposit', {
              valueAsNumber: true,
            })}
          />
          <FormMessage name="firstDeposit" />
        </div>
        <div className="flex items-center gap-2.5 p-5 rounded-2xl border border-[#DDF2DB] bg-[#EBFDE9] text-[#536751] text-xs sm:text-sm">
          Minimum initial deposit = Min_Deposit × (200 / ROI). This ensures fair
          gameplay.
        </div>
        {formCtx.formState.errors.root && (
          <div className="flex items-center text-red-600 text-xs sm:text-sm">
            {formCtx.formState.errors.root.message}
          </div>
        )}
      </div>
      <div className="flex flex-col justify-center items-center gap-6">
        <button
          className="btn w-[279px]"
          disabled={isInvalid || disabledBtn || isLoading}
          onClick={() => {
            handleNext().catch(console.warn);
          }}
        >
          {isLoading ? 'Loading...' : 'Launch game'}
        </button>
        <button className="flex items-center gap-4" onClick={handlePrev}>
          <span className="rotate-180">
            <Icons.ArrowRight color="black" />
          </span>
          Previous step
        </button>
      </div>
    </NewGameStep>
  );
}
