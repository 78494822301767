import {Connection, PublicKey} from '@solana/web3.js';
import {struct, u32, u8} from '@solana/buffer-layout';
import {publicKey, u64} from '@solana/buffer-layout-utils';
import {TOKEN_PROGRAM_ID} from '@solana/spl-token';

export const formatAddress = (
  address: string,
  startChars = 6,
  endChars = 4
) => {
  return `${address.slice(0, startChars)}...${address.slice(-endChars)}`;
};

export interface RawSPLTokenAccount {
  mint: PublicKey;
  owner: PublicKey;
  amount: bigint;
  delegateOption: bigint;
  delegate: PublicKey;
  state: number;
  isNativeOption: bigint;
  isNative: bigint;
  delegatedAmount: bigint;
  closeAuthorityOption: bigint;
  closeAuthority: PublicKey;
}

export const SPL_ACCOUNT_LAYOUT = struct<RawSPLTokenAccount>([
  publicKey('mint'),
  publicKey('owner'),
  u64('amount'),
  u32('delegateOption'),
  publicKey('delegate'),
  u8('state'),
  u32('isNativeOption'),
  u64('isNative'),
  u64('delegatedAmount'),
  u32('closeAuthorityOption'),
  publicKey('closeAuthority'),
]);

export async function getWalletTokenAccount(
  connection: Connection,
  wallet: PublicKey
) {
  const walletTokenAccount = await connection.getTokenAccountsByOwner(wallet, {
    programId: TOKEN_PROGRAM_ID,
  });

  return walletTokenAccount.value.map(i => ({
    pubkey: i.pubkey,
    programId: i.account.owner,
    accountInfo: SPL_ACCOUNT_LAYOUT.decode(i.account.data),
  }));
}

export async function getTokenBalance(
  connection: Connection,
  tokenAddress: string,
  wallet: PublicKey,
  tokenDecimals = 6
) {
  const walletTokenAccounts = await getWalletTokenAccount(connection, wallet);
  const tokenAccount = walletTokenAccounts.find(
    acc => acc.accountInfo.mint.toString() === tokenAddress
  );
  if (!tokenAccount) {
    throw new Error(`You do not have any ${tokenAddress} coins`);
  }

  return Number(tokenAccount.accountInfo.amount / BigInt(10 ** tokenDecimals));
}
