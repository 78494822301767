import {DialogClose} from '@radix-ui/react-dialog';
import {AnimatePresence} from 'framer-motion';
import {useIsMedium} from 'lib/useBreakpoints';
import {Controller, useForm} from 'react-hook-form';
import {DialogPortal, DialogRoot} from 'ui-kit/Dialog';
import {DoubleRange} from 'ui-kit/FormRange/DoubleRange';
import {Icons} from 'ui-kit/Icons';
import DatePicker from './DatePicker';
import {useEffect, useState} from 'react';
import {gameService} from 'services/games';
import {FormDropdown} from 'ui-kit/FormDropdown';
import {Coin} from 'types';
import {Price} from 'services/price';
import {getTokenDecimals} from 'utils/game';

export type Filters = {
  fromJackpot: number;
  toJackpot: number | null;
  fromBet: number;
  toBet: number | null;
  fromDate: Date | null;
  toDate: Date | null;
  fromMinute: number;
  toMinute: number;
  token: string;
};

type DialogProps = {
  isOpen: boolean;
  page: 'all-games' | 'my-games' | 'hosted-games';
  setFilters: (filters: Filters) => void;
  onOpenChange: (open: boolean) => void;
};

type FilterFormType = {
  jackpotSize: string; // Updated to string for easier handling of min-max ranges
  betAmount: string; // Updated to string for easier handling of min-max ranges
  minutes: string;
  token: string;
};

function formatDate(date: Date): string {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

/**
 * Fallback value for filtering games
 */
const MAX_PONZI_RANGE = 100000000;

const FiltersDialog = ({
  isOpen,
  page,
  onOpenChange,
  setFilters,
}: DialogProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [maxJackpot, setMaxJackpot] = useState(MAX_PONZI_RANGE);
  const [maxBet, setMaxBet] = useState(MAX_PONZI_RANGE);
  const [coins, setCoins] = useState<Coin[]>([]);
  const [decimals, setDecimals] = useState<number>(6);
  const isMd = useIsMedium();

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        const coins = await Price.fetchCoins();
        setCoins(coins);
        const d = await getTokenDecimals(
          (JSON.parse(form.watch('token')) as {addr: string}).addr
        );
        if (d) setDecimals(d);
      } catch (e) {
        console.error(e);
      }
    };
    fetchCoins().catch(console.error);
  }, []);

  const form = useForm<FilterFormType>({
    defaultValues: {
      jackpotSize: '1',
      betAmount: '1',
      minutes: '1,600',
      token: '',
    },
  });

  useEffect(() => {
    // Load maximum values for jackpot and bet
    gameService
      .getMaxJackpot()
      .then(res => {
        if (res) {
          setMaxJackpot(res.maxJackpot / 10 ** decimals);
        }
      })
      .catch(e => console.warn(e));
    gameService
      .getMaxBet()
      .then(res => {
        if (res) {
          setMaxBet(res.maxBet / 10 ** decimals);
        }
      })
      .catch(e => console.warn(e));
  }, []);

  useEffect(() => {
    // Load filters from localStorage when dialog opens
    if (isOpen) {
      const savedFilters = localStorage.getItem(`filters-${page}`);
      if (savedFilters) {
        try {
          const filters: Filters = JSON.parse(savedFilters);
          setStartDate(filters.fromDate ? new Date(filters.fromDate) : null);
          setEndDate(filters.toDate ? new Date(filters.toDate) : null);
          form.setValue(
            'jackpotSize',
            `${filters.fromJackpot},${filters.toJackpot || maxJackpot}`
          );
          form.setValue(
            'betAmount',
            `${filters.fromBet},${filters.toBet || maxBet}`
          );
          form.setValue('minutes', `${filters.fromMinute},${filters.toMinute}`);
          form.setValue('token', filters.token);
        } catch (error) {
          console.warn('Failed to parse saved filters:', error);
        }
      }
    }
  }, [isOpen, page, form, maxJackpot, maxBet]);

  const handleApplyFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const jackpot = form
      .getValues('jackpotSize')
      .toString()
      .split(',')
      .map(Number);

    const minutes = form.getValues('minutes').toString().split(',').map(Number);
    const bet = form.getValues('betAmount').toString().split(',').map(Number);
    const token = form.getValues('token');

    const newFilters: Filters = {
      fromJackpot: jackpot[0],
      toJackpot: jackpot[1] === maxJackpot ? null : jackpot[1],
      fromBet: bet[0],
      toBet: bet[1] === maxBet ? null : bet[1],
      fromDate: startDate,
      toDate: endDate,
      fromMinute: Number(minutes[0]),
      toMinute: Number(minutes[1]),
      token,
    };

    // Save filters to localStorage
    localStorage.setItem(`filters-${page}`, JSON.stringify(newFilters));

    // Apply filters and close dialog
    setFilters(newFilters);
    onOpenChange(false);
  };

  const handleResetFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setStartDate(null);
    setEndDate(null);
    form.reset({
      jackpotSize: `1,${maxJackpot}`,
      betAmount: `1,${maxBet}`,
      minutes: '1,600',
      token: '',
    });
    const defaultFilters: Filters = {
      fromJackpot: 1,
      toJackpot: null,
      fromBet: 1,
      toBet: null,
      fromDate: null,
      toDate: null,
      fromMinute: 1,
      toMinute: 600,
      token: '',
    };

    // Save default filters to localStorage
    localStorage.setItem(`filters-${page}`, JSON.stringify(defaultFilters));

    // Reset filters
    setFilters(defaultFilters);
  };

  return (
    <DialogRoot open={isOpen} onOpenChange={onOpenChange}>
      <AnimatePresence>
        {isOpen && (
          <DialogPortal forceMount variant={!isMd ? 'mobile' : undefined}>
            <div className="relative overflow-x-hidden flex flex-col w-full md:w-[543px] gap-6 px-5 pt-8 pb-6 md:gap-[2.125rem] md:px-[3.125rem] md:py-[1.75rem] max-h-[90vh] overflow-y-auto">
              <DialogClose className="absolute top-[22px] right-[17px] flex justify-center items-center w-5 h-5 outline-none md:top-[34px] md:right-[31px]">
                <Icons.Close />
              </DialogClose>
              <h2 className="text-center text-xl leading-9 text-[#101520] md:text-[1.75rem]">
                Filters
              </h2>
              <div>
                <div className="mb-[40px] text-[14px]">Jackpot size</div>
                <Controller
                  name="jackpotSize"
                  control={form.control}
                  render={({field}) => (
                    <DoubleRange
                      label=""
                      name={field.name}
                      min={1}
                      max={maxJackpot}
                      minLabel="1 PONZI"
                      maxLabel={`${maxJackpot} PONZI`}
                      tooltipPostfix=" PONZI"
                      value={
                        field.value
                          ? field.value.split(',').map(v => Number(v))
                          : [1, maxJackpot]
                      }
                      onChange={(values: number[]) =>
                        field.onChange(values.join(','))
                      }
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </div>
              <div className="relative">
                <div className="mb-[8px] text-[14px]">Creation time</div>
                <button
                  type="button"
                  className="w-full outline-none"
                  onClick={() => setDatePickerOpen(!datePickerOpen)}
                >
                  <div className="relative">
                    <div
                      className={`text-black py-[14.5px] px-[20px] text-left w-full rounded-[12px] text-[14px] bg-[#F9F9F9] border-[1px] ${datePickerOpen ? 'border-black' : 'border-[#F9F9F9]'}`}
                    >
                      {startDate
                        ? `${formatDate(startDate)}${endDate && formatDate(startDate) !== formatDate(endDate) ? ` – ${formatDate(endDate)}` : ''}`
                        : 'All time'}
                    </div>
                    <div className="absolute right-[20px] top-[50%] z-10 translate-y-[-50%]">
                      <Icons.Calendar />
                    </div>
                  </div>
                </button>
                {datePickerOpen && (
                  <div className="fixed w-[90vw] sm:w-full md:max-w-[443px] z-50 translate-y-[12.5px]">
                    <DatePicker
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                )}
              </div>
              <div>
                <div className="mb-[40px] text-[14px]">Bet amount</div>
                <Controller
                  name="betAmount"
                  control={form.control}
                  render={({field}) => (
                    <DoubleRange
                      label=""
                      name={field.name}
                      min={1}
                      max={maxBet}
                      minLabel="1 PONZI"
                      maxLabel={`${maxBet} PONZI`}
                      tooltipPostfix=" PONZI"
                      value={
                        field.value
                          ? field.value.split(',').map(v => Number(v))
                          : [1, maxJackpot]
                      }
                      onChange={(values: number[]) =>
                        field.onChange(values.join(','))
                      }
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </div>
              <div>
                <div className="mb-[40px] text-[14px]">Game timer</div>
                <Controller
                  name="minutes"
                  control={form.control}
                  render={({field}) => (
                    <DoubleRange
                      label=""
                      name={field.name}
                      min={1}
                      max={600}
                      minLabel="1 MINUTE"
                      maxLabel={`600 MINUTES`}
                      tooltipPostfix={
                        field.value === '1' ? ' MINUTE' : ' MINUTES'
                      }
                      value={
                        field.value
                          ? field.value.split(',').map(v => Number(v))
                          : [1, 600]
                      }
                      onChange={(values: number[]) =>
                        field.onChange(values.join(','))
                      }
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </div>
              {/* <Controller
                name="token"
                control={form.control}
                render={({field}) => (
                  <FormDropdown
                    selected={
                      (field.value !== ''
                        ? (JSON.parse(field.value) as {addr: string})
                        : {addr: ''}
                      ).addr
                    }
                    selectOption={(
                      icon: string,
                      addr: string,
                      symbol: string
                    ) => {
                      field.onChange(JSON.stringify({icon, addr, symbol}));
                    }}
                    options={coins}
                    label="Choose a coin"
                  />
                )}
              /> */}
              <div className="flex flex-col items-center gap-[24px]">
                <div>
                  <button
                    type="button"
                    onClick={handleApplyFilters}
                    className="bg-black rounded-[41px] pt-[17px] pb-[15px] px-[75px] text-white"
                  >
                    <span>Apply filters</span>
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={handleResetFilters}
                    className="underline"
                  >
                    Reset all
                  </button>
                </div>
              </div>
            </div>
          </DialogPortal>
        )}
      </AnimatePresence>
    </DialogRoot>
  );
};

export default FiltersDialog;
