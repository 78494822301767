import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './App';
import './index.css';
import SolanaCtxProvider from 'lib/web3-react/components/SolanaReact';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SolanaCtxProvider>
      <App />
    </SolanaCtxProvider>
  </React.StrictMode>
);
