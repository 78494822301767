import {useWallet} from '@solana/wallet-adapter-react';
import {Icons} from 'ui-kit/Icons';
import {SolanaValue} from 'ui-kit/SolanaValue';

interface NewGameCardProps {
  name: string;
  roi: number;
  minDeposit: number;
  maxDeposit: number;
  imgSrc: string;
  icon?: string;
  symbol?: string;
}

function EthereumValue({value}: {value: number}) {
  const {connected} = useWallet();
  return (
    <div className="flex items-center gap-2 text-sm">
      {connected ? (
        <div className="flex items-center justify-center w-2 h-2 rounded-full bg-white sm:w-4 sm:h-4">
          <Icons.Solana className="w-[7px] h-[10px] sm:w-[10px] sm:h-[16px]" />
        </div>
      ) : (
        <div className="flex items-center justify-center w-4 h-4 rounded-full bg-white sm:w-6 sm:h-6">
          <Icons.Ethereum className="w-[7px] h-[10px] sm:w-[10px] sm:h-[16px]" />
        </div>
      )}
      <span className="block w-[62px] text-xs sm:text-sm">{value} ETH</span>
    </div>
  );
}

export function NewGameCard({
  name,
  roi,
  minDeposit,
  maxDeposit,
  imgSrc,
  icon,
  symbol,
}: NewGameCardProps) {
  const {connected} = useWallet();
  return (
    <div className="flex flex-col sm:flex-row">
      <img
        src={imgSrc}
        alt={`Thumbnail of "${name}" game`}
        className="min-w-[134px] h-[118px] rounded-t-2xl bg-[#F9F9F9] object-cover sm:h-[160px] sm:rounded-tr-none sm:rounded-l-2xl"
      />
      <div className="flex flex-col p-5 gap-6 rounded-b-2xl sm:rounded-bl-none sm:rounded-r-2xl bg-[#F9F9F9] font-medium text-[#101520] sm:p-6 sm:gap-9">
        <h5 className="max-w-[300px] text-base truncate sm:text-lg">{name}</h5>
        <div className="flex gap-6">
          <div className="flex flex-col gap-2">
            <span className="text-[0.625rem] text-[#A0A4AC] sm:text-xs">
              ROI
            </span>
            <span className="block w-[62px] text-xs sm:text-sm">{roi}%</span>
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-[0.625rem] text-[#A0A4AC] sm:text-xs">
              Min. limit
            </span>
            {connected ? (
              <SolanaValue symbol={symbol} icon={icon} value={minDeposit} />
            ) : (
              <EthereumValue value={minDeposit} />
            )}
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-[0.625rem] text-[#A0A4AC] sm:text-xs">
              Max. limit
            </span>
            {connected ? (
              <SolanaValue symbol={symbol} icon={icon} value={maxDeposit} />
            ) : (
              <EthereumValue value={maxDeposit} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
