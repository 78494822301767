import {useWallet} from '@solana/wallet-adapter-react';
import {Icons} from './Icons';
import {ConnectWalletDialog} from 'screens/Dashboard/components/ConnectWalletDialog';
import {useState} from 'react';

type ButtonProps = {
  icon: 'link' | 'check';
  handleCopy: () => void;
};

const CopyButton = ({icon, handleCopy}: ButtonProps) => {
  const {connected} = useWallet();
  if (connected) {
    return (
      <button
        className="flex gap-2 items-center w-fit"
        type="button"
        onClick={handleCopy}
      >
        <div className="text-sm rounded-full px-2 py-2 flex items-center gap-2 bg-gray-100 text-center justify-center">
          {icon === 'link' ? (
            <>
              <Icons.Link />
            </>
          ) : (
            <>
              <Icons.Check />
            </>
          )}
        </div>
      </button>
    );
  }
  return (
    <ConnectWalletDialog>
      <button className="flex gap-2 items-center w-fit" type="button">
        <div className="text-sm rounded-full px-2 py-2 flex items-center gap-2 bg-gray-100 text-center justify-center">
          {icon === 'link' ? (
            <>
              <Icons.Link />
            </>
          ) : (
            <>
              <Icons.Check />
            </>
          )}
        </div>
      </button>
    </ConnectWalletDialog>
  );
};

export default CopyButton;
