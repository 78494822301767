import {
  SliderRange,
  Root as SliderRoot,
  SliderThumb,
  SliderTrack,
} from '@radix-ui/react-slider';
import {ReactNode, forwardRef} from 'react';
import {ControllerRenderProps} from 'react-hook-form';
import './styles.css';

interface FormRangeProps extends Omit<ControllerRenderProps, 'ref'> {
  label: ReactNode;
  min: number;
  max: number;
  step?: number;
  minLabel?: string;
  maxLabel?: string;
  tooltipPostfix?: string;
}

export const DoubleRange = forwardRef<HTMLInputElement, FormRangeProps>(
  (
    {label, min, max, step = 1, minLabel, maxLabel, tooltipPostfix, ...props},
    ref
  ) => {
    const value = Array.isArray(props.value) ? props.value : [min, max];

    const handleValueChange = (newValue: number[]) => {
      props.onChange(newValue);
    };

    return (
      <label className="flex flex-col gap-2">
        <span className="text-sm">{label}</span>
        <span className="flex items-end">
          <SliderRoot
            className="relative flex items-center w-full h-5 cursor-pointer"
            ref={ref}
            min={min}
            max={max}
            step={step}
            defaultValue={value}
            value={value}
            onValueChange={handleValueChange}
          >
            <SliderTrack className="relative grow h-1 rounded-full bg-[#F9F9F9]">
              <SliderRange className="absolute h-full rounded-full bg-black" />
            </SliderTrack>
            <SliderThumb className="flex justify-center w-5 h-5 rounded-full border-4 border-black bg-white outline-none">
              <div className="form-range-tooltip">
                <div>{value[0]}</div>
                {tooltipPostfix && <div>{tooltipPostfix}</div>}
              </div>
            </SliderThumb>
            <SliderThumb className="flex justify-center w-5 h-5 rounded-full border-4 border-black bg-white outline-none">
              <div className="form-range-tooltip">
                <div>{value[1] !== max ? value[1] : 'Unlimited'}</div>
                {value[1] !== max && tooltipPostfix && (
                  <div>{tooltipPostfix}</div>
                )}
              </div>
            </SliderThumb>
          </SliderRoot>
        </span>
        <span className="flex justify-between items-center text-sm text-black text-opacity-50">
          <span>{minLabel}</span>
          <span>{maxLabel}</span>
        </span>
      </label>
    );
  }
);
