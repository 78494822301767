import {ReactNode, useMemo} from 'react';
import {ConnectionProvider, WalletProvider} from '@solana/wallet-adapter-react';
import {WalletAdapterNetwork} from '@solana/wallet-adapter-base';
import {WalletModalProvider} from '@solana/wallet-adapter-react-ui';
import {clusterApiUrl} from '@solana/web3.js';
import {
  OntoWalletAdapter,
  PhantomWalletAdapter,
  SaifuWalletAdapter,
  SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';

const SolanaCtxProvider = ({children}: {children: ReactNode}) => {
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]); // RPC endpoint
  const wallets = useMemo(
    () => [
      new SolflareWalletAdapter(),
      new SaifuWalletAdapter(),
      new OntoWalletAdapter(),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default SolanaCtxProvider;
