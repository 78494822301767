import {Connection} from '@solana/web3.js';

export const MIN_DEPOSIT = 0.001;
export const MIN_DEPOSIT_SOL = 1;

export const MAX_DEPOSIT = 1000;
export const MAX_DEPOSIT_SOL = 1000000;

export const GAMES_PER_PAGE = 8;

// also present in server/constants.js
export const apiUrls = {
  development: 'http://localhost:5001',
  staging: 'https://ponzimarket-production.up.railway.app',
  production: 'https://ponzi-backend-production.up.railway.app',
};

export const gameContracts = {
  development: {
    gameFactory: '0x519d76A95545ef8c230505858305D23f3E1cff76',
  },
  staging: {
    gameFactory: '0xfCd5dA8c2682e5d17235A5010A01bf6B51B2841D',
  },
  production: {
    gameFactory: '0xfCd5dA8c2682e5d17235A5010A01bf6B51B2841D',
  },
};

export const STAGE = (process.env.REACT_APP_STAGE ||
  'development') as keyof typeof apiUrls;

export const API_BASE_URL = apiUrls[STAGE];
export const GAME_CONTRACTS = gameContracts[STAGE];

export const tokens = {
  development: '6nFo5fYDS7nCPmYEEUMGPkndfDvXaTx73uohdzQFjZ1F',
  staging: '6nFo5fYDS7nCPmYEEUMGPkndfDvXaTx73uohdzQFjZ1F',
  production: 'tqrRt3AsuGa3aYYSvABMDrPJEWVzpAnh3p6LxXGpump',
};

const nodes = {
  // development: 'https://api.devnet.solana.com',
  development:
    'https://devnet.helius-rpc.com/?api-key=6cd1b0e7-cd8e-415d-8413-c70578fa4231',
  staging: 'https://api.devnet.solana.com',
  production:
    'https://mainnet.helius-rpc.com/?api-key=00758c81-e809-48d0-b659-a72772acf8dc',
};

const TOKEN = (process.env.REACT_APP_STAGE ||
  'development') as keyof typeof tokens;
const NODE = (process.env.REACT_APP_STAGE ||
  'development') as keyof typeof nodes;

export const TOKEN_ADDRESS = tokens[TOKEN];

export const connection = new Connection(nodes[NODE], 'confirmed');
