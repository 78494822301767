import {useWallet} from '@solana/wallet-adapter-react';
import {useWeb3React} from '@web3-react/core';
import {Provider, SolanaProvider} from 'lib/web3-react/types';
import {getProviderByConnector, getSolanaProvider} from 'lib/web3-react/utils';

const metaMask = require('../assets/wallets/metaMask.png') as string;

export const useConnectedWalletIcon = (): string => {
  const {connector} = useWeb3React();
  const {connected, wallet} = useWallet();

  const icons: Record<Provider | SolanaProvider, string> = {
    MetaMask: 'metaMask',
    WalletConnect: 'walletConnect',
    Coinbase: 'coinbase',
    Phantom: 'phantom',
    Solflare: 'solflare',
    Saifu: 'saifu',
    Onto: 'onto',
  };

  if (connected && wallet) {
    try {
      const provider = getSolanaProvider(wallet.adapter.name.toString());
      return require(`../assets/wallets/${icons[provider]}.png`);
    } catch (_) {
      return metaMask;
    }
  } else if (connector) {
    return require(
      `../assets/wallets/${icons[getProviderByConnector(connector)]}.png`
    );
  }
  return metaMask;
};
