import {motion} from 'framer-motion';
import {useState} from 'react';
import {Icons} from 'ui-kit/Icons';

interface DatePickerProps {
  onRangeSelect?: (startDate: Date | null, endDate: Date | null) => void;
  initialDate?: Date;
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
}

const DatePicker = ({
  onRangeSelect,
  initialDate = new Date(),
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: DatePickerProps) => {
  const [currentDate, setCurrentDate] = useState(initialDate);
  const [isSelecting, setIsSelecting] = useState(false);

  const getDaysInMonth = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const handleDateClick = (date: Date) => {
    if (!isSelecting || !startDate) {
      setStartDate(date);
      setEndDate(null);
      setIsSelecting(true);
    } else {
      if (date < startDate) {
        setStartDate(date);
        setEndDate(startDate);
      } else {
        setEndDate(date);
      }
      setIsSelecting(false);
      onRangeSelect?.(startDate, date < startDate ? startDate : date);
    }
  };

  const isDateInRange = (date: Date) => {
    if (!startDate || !endDate) return false;
    return date >= startDate && date <= endDate;
  };

  const changeMonth = (offset: number) => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + offset, 1)
    );
  };

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const daysInMonth = getDaysInMonth(currentDate);
  const firstDay = getFirstDayOfMonth(currentDate);

  const prevMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  const prevMonthDays = getDaysInMonth(prevMonthDate);

  const nextMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );

  const days = [
    ...[...Array(firstDay)].map((_, i) => prevMonthDays - firstDay + i + 1), // Days from the previous month
    ...[...Array(daysInMonth)].map((_, i) => i + 1), // Days from the current month
    ...[...Array(42 - (firstDay + daysInMonth))].map((_, i) => i + 1), // Days from the next month
  ];

  return (
    <motion.div
      initial={{opacity: 0, translateY: -10}}
      animate={{opacity: 1, translateY: 0}}
      exit={{opacity: 0}}
      transition={{duration: 0.2}}
      className="bg-[#F9F9F9] rounded-[12px] p-[20px]"
    >
      <div className="flex items-center justify-between mb-4">
        <div className="font-regular text-[18px]">
          <span className="font-semibold">
            {monthNames[currentDate.getMonth()]}
          </span>{' '}
          {currentDate.getFullYear()}
        </div>
        <div className="flex items-center gap-[4px]">
          <button
            type="button"
            onClick={e => {
              e.preventDefault();
              changeMonth(-1);
            }}
            className="py-[8px] px-[12px] bg-black rounded-[8px]"
          >
            <Icons.CalendarArrow />
          </button>
          <button
            type="button"
            onClick={e => {
              e.preventDefault();
              changeMonth(1);
            }}
            className="rotate-180 py-[8px] px-[12px] bg-black rounded-[8px]"
          >
            <Icons.CalendarArrow />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-1 mb-2">
        {weekDays.map(day => (
          <div key={day} className="text-center text-sm font-medium mb-[16px]">
            {day}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-7 gap-1">
        {days.map((day, index) => {
          const isPrevMonth = index < firstDay;
          const isNextMonth = index >= firstDay + daysInMonth;
          const displayDate = isPrevMonth
            ? new Date(
                prevMonthDate.getFullYear(),
                prevMonthDate.getMonth(),
                day
              )
            : isNextMonth
              ? new Date(
                  nextMonthDate.getFullYear(),
                  nextMonthDate.getMonth(),
                  day
                )
              : new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  day
                );

          const isStart = startDate?.getTime() === displayDate.getTime();
          const isEnd = endDate?.getTime() === displayDate.getTime();
          const isInRange = isDateInRange(displayDate);

          return (
            <button
              type="button"
              key={`${displayDate}-${index}`}
              onClick={e => {
                e.preventDefault();
                handleDateClick(displayDate);
              }}
              className={`
                relative h-[43px] flex items-center justify-center text-sm
                transition-colors duration-200 rounded-[6px] hover:bg-[#f0f0f0] text-[12px]
                ${isPrevMonth || isNextMonth ? 'text-gray-400' : ''}
                ${isStart || isEnd ? '!bg-black text-white' : ''}
                ${isInRange && !isStart && !isEnd ? 'bg-[#E9E9E9]' : 'bg-white'}
              `}
            >
              <span className="absolute top-[8px] left-[8px] z-10">{day}</span>
            </button>
          );
        })}
      </div>
      <div className="mt-[12px] flex justify-center">
        <button
          type="button"
          onClick={e => {
            e.preventDefault();
            setEndDate(null);
            setStartDate(null);
            setCurrentDate(initialDate);
          }}
          className="text-black/50 flex items-center gap-[2px]"
        >
          <div className="text-[12px]">Clear choice</div>
          <div>
            <Icons.Close width={12} height={12} />
          </div>
        </button>
      </div>
    </motion.div>
  );
};

export default DatePicker;
