import {useWallet} from '@solana/wallet-adapter-react';
import {API_BASE_URL} from 'constants/index';
import {useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useAllRefsStore} from '../store';
import {Icons} from 'ui-kit/Icons';
import {formatAddress} from 'utils/web3';
import {formatNumberWithFixedDecimals} from 'lib/formatNum';

const URL = `${API_BASE_URL}/api/users/referrals/get`;

export type Referral = {
  id: string;
  address: string;
  amount: number;
};

export type Response = {
  data: {
    id: string;
    referrerWallet: string;
    wallet: string;
    amount: string;
    createdAt: string;
  }[];
  bonus: string;
  total: string;
  page: string;
  limit: string;
};

const ReferralList = ({
  sorting,
}: {
  sorting: 'Lowest amount' | 'Highest amount';
}) => {
  const {isLoading, referrals, setRefs, page, hasMore, setHasMore, setPage} =
    useAllRefsStore();
  const [isFirstFetch, setFirstFetch] = useState(true);
  const {publicKey} = useWallet();

  useEffect(() => {
    const firstFetch = async () => {
      if (!publicKey || !isFirstFetch) return;
      setFirstFetch(false);
      console.log(sorting);
      const response = await fetch(
        `${URL}?wallet=${publicKey.toString()}&page=0&limit=8&sort=${sorting === 'Lowest amount' ? 'lowest' : 'highest'}`
      );
      const apiResponse: Response = await response.json();
      const mapped: Referral[] = apiResponse.data.map(item => ({
        id: item.id,
        address: item.wallet,
        amount: Number(item.amount),
      }));
      setHasMore(apiResponse.data.length === 8);
      setRefs(mapped);
    };
    firstFetch().catch(e => console.error(e));
  }, [publicKey, isFirstFetch, setHasMore, setRefs, sorting]);

  useEffect(() => {
    setFirstFetch(true);
    setPage(0);
    const firstFetch = async () => {
      if (!publicKey || !isFirstFetch) return;
      setFirstFetch(false);
      console.log(sorting);
      const response = await fetch(
        `${URL}?wallet=${publicKey.toString()}&page=0&limit=8&sort=${sorting === 'Lowest amount' ? 'lowest' : 'highest'}`
      );
      const apiResponse: Response = await response.json();
      const mapped: Referral[] = apiResponse.data.map(item => ({
        id: item.id,
        address: item.wallet,
        amount: Number(item.amount),
      }));
      setHasMore(apiResponse.data.length === 8);
      setRefs(mapped);
    };
    firstFetch().catch(e => console.error(e));
  }, [sorting]);

  const handleNext = async () => {
    if (!publicKey) return;

    try {
      const nextPage = page + 1;
      const response = await fetch(
        `${URL}?wallet=${publicKey.toString()}&page=${nextPage}&limit=8&sort=${sorting === 'Lowest amount' ? 'lowest' : 'highest'}`
      );
      const apiResponse: Response = await response.json();
      const mapped: Referral[] = apiResponse.data.map(item => ({
        id: item.id,
        address: item.wallet,
        amount: Number(item.amount),
      }));

      setHasMore(apiResponse.data.length === 8);
      setPage(nextPage);
      setRefs([...referrals, ...mapped]);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="p-[32px] bg-white rounded-[16px] flex-1 w-full">
      <div id="scroll-container" className="overflow-auto max-h-[70vh]">
        <table className="w-full">
          <thead>
            <tr className="text-left text-[#A0A4AC]">
              <th className="leading-[20px] text-[14px] font-medium pb-[32px]">
                Address
              </th>
              <th className="leading-[20px] text-[14px] font-medium pb-[32px] text-right xl:pr-[120px]">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {referrals.map(referral => (
              <tr key={referral.id}>
                <td>
                  <div className="py-[6px] text-[14px] font-medium leading-[20px]">
                    {formatAddress(referral.address)}
                  </div>
                </td>
                <td>
                  <div className="flex justify-end items-center gap-[8px]">
                    <Icons.Solana />
                    <div className="xl:mr-[49px]">
                      {formatNumberWithFixedDecimals(referral.amount, 5)} PONZI
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="w-full flex justify-center mt-[32px]">
          <button
            type="button"
            onClick={handleNext}
            disabled={!hasMore}
            className={`bg-black rounded-full text-white py-2 px-4 text-[14px] ${!hasMore ? 'opacity-50' : ''}`}
          >
            Load more
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReferralList;
