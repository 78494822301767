import {motion} from 'framer-motion';
import {Icons as IconKit} from 'ui-kit/Icons';

type RefOptions = 'Lowest amount' | 'Highest amount';

export type SortOptions =
  | 'Newest first'
  | 'Oldest first'
  | 'Most popular'
  | 'Least popular';

type SortDialogProps = {
  selectedSort: SortOptions | RefOptions;
  className?: string;
  position: string;
  setSelectedSort: (sort: SortOptions | RefOptions) => void;
  setIsOpen: (isOpen: boolean) => void;
};

const DialogRow = (props: {
  option: SortOptions | RefOptions;
  selected: SortOptions | RefOptions;
  setSort: (sort: SortOptions | RefOptions) => void;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const isSelected = props.selected === props.option;
  if (isSelected) {
    return (
      <button type="button" className="flex items-center gap-[12px]">
        <div className="rounded-[4px] bg-[#000000]">
          <IconKit.Check stroke="#F4F4F4" width={'16px'} height={'16px'} />
        </div>
        <div className="translate-y-[2px]">{props.option}</div>
      </button>
    );
  }
  return (
    <button
      type="button"
      onClick={() => {
        props.setSort(props.option);
        props.setIsOpen(false);
      }}
      className="flex items-center gap-[12px]"
    >
      <div className="w-[16px] h-[16px] rounded-[4px] bg-[#F4F4F4]"></div>
      <div className="translate-y-[2px]">{props.option}</div>
    </button>
  );
};

const SortDialog = ({
  selectedSort,
  setSelectedSort,
  className,
  setIsOpen,
  position,
}: SortDialogProps) => {
  if (selectedSort === 'Lowest amount' || selectedSort === 'Highest amount') {
    return (
      <motion.div
        className={`absolute ${position} z-10 bg-white rounded-[16px] p-[16px] w-fit min-w-[194px] flex flex-col gap-[16px] ${className ? className : ''}`}
        style={{
          boxShadow: '0px 4px 10px 0px #0000001A',
        }}
        initial={{opacity: 0, scale: 0.9, y: -10}}
        animate={{opacity: 1, scale: 1, y: 0}}
        exit={{opacity: 0, scale: 0.9, y: -10}}
        transition={{duration: 0.1, ease: 'easeInOut'}}
      >
        <div>
          <DialogRow
            setIsOpen={setIsOpen}
            option={'Highest amount'}
            selected={selectedSort}
            setSort={setSelectedSort}
          />
        </div>
        <div className="">
          <DialogRow
            setIsOpen={setIsOpen}
            option={'Lowest amount'}
            selected={selectedSort}
            setSort={setSelectedSort}
          />
        </div>
      </motion.div>
    );
  }
  return (
    <motion.div
      className={`absolute ${position} z-10 bg-white rounded-[16px] p-[16px] w-fit min-w-[194px] flex flex-col gap-[16px] ${className ? className : ''}`}
      style={{
        boxShadow: '0px 4px 10px 0px #0000001A',
      }}
      initial={{opacity: 0, scale: 0.9, y: -10}}
      animate={{opacity: 1, scale: 1, y: 0}}
      exit={{opacity: 0, scale: 0.9, y: -10}}
      transition={{duration: 0.1, ease: 'easeInOut'}}
    >
      <div>
        <DialogRow
          setIsOpen={setIsOpen}
          option={'Newest first'}
          selected={selectedSort}
          setSort={setSelectedSort}
        />
      </div>
      <div className="">
        <DialogRow
          setIsOpen={setIsOpen}
          option={'Oldest first'}
          selected={selectedSort}
          setSort={setSelectedSort}
        />
      </div>
      <div>
        <DialogRow
          setIsOpen={setIsOpen}
          option={'Most popular'}
          selected={selectedSort}
          setSort={setSelectedSort}
        />
      </div>
      <div>
        <DialogRow
          setIsOpen={setIsOpen}
          option={'Least popular'}
          selected={selectedSort}
          setSort={setSelectedSort}
        />
      </div>
    </motion.div>
  );
};

export default SortDialog;
