export function formatNumberWithCommas(n: number) {
  return n.toLocaleString();
}

export function formatNumberWithFixedDecimals(n: number, decimals: number) {
  const formatted = n.toLocaleString();
  const decimalsStr = n.toFixed(decimals);
  const split = formatted.split('.');
  return `${split[0]}.${decimalsStr.split('.')[1]}`;
}
