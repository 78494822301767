import {AnimatePresence, motion} from 'framer-motion';
import {useEffect, useState} from 'react';
import SortDialog, {SortOptions} from 'ui-kit/SortDialog/SortDialog';
import TotalEarned from './components/TotalEarned';
import ReferralList, {Referral, Response} from './components/ReferralList';
import {API_BASE_URL} from 'constants/index';
import {useWallet} from '@solana/wallet-adapter-react';

const URL = `${API_BASE_URL}/api/users/referrals/get`;

const ReferralsView = () => {
  const {publicKey} = useWallet();
  const [sortOpen, setSortOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState<
    'Lowest amount' | 'Highest amount'
  >('Highest amount');
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (publicKey) {
      const fetchReferrals = async () => {
        const response = await fetch(
          `${URL}?wallet=${publicKey.toString()}&page=0&limit=8`
        );
        const apiResponse: Response = await response.json();
        setCount(Number());
        setTotal(Number(apiResponse.bonus));
      };
      fetchReferrals().catch(e => console.error(e));
    }
  }, [publicKey]);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="text-[24px] leading-[28px] mt-[5px]">Referrals</div>
          <span className="flex justify-center items-center px-3 py-1 rounded-lg bg-white text-xs sm:text-sm">
            {count}
          </span>
        </div>
        <div className="relative hidden sm:flex items-center gap-[16px]">
          <div className="text-[#A0A4AC]">Sort by:</div>
          <button
            type="button"
            className="text-[16px] font-medium flex items-center gap-[12px]"
            onClick={() => setSortOpen(!sortOpen)}
          >
            {selectedSort}
            <motion.div
              style={{
                height: '5px',
                aspectRatio: '2',
                clipPath: 'polygon(50% 100%,100% 0,0 0)',
                background: '#000',
                rotate: 180,
              }}
              animate={{rotate: sortOpen ? 0 : 180}}
              transition={{
                duration: 0.15,
                ease: 'easeInOut',
              }}
            />
          </button>
          {sortOpen && (
            <AnimatePresence>
              <SortDialog
                position="top-8 -right-2"
                setIsOpen={setSortOpen}
                selectedSort={selectedSort}
                setSelectedSort={sort =>
                  setSelectedSort(sort as 'Lowest amount' | 'Highest amount')
                }
              />
            </AnimatePresence>
          )}
        </div>
      </div>
      <div className="mt-[30px] flex flex-col sm:flex-row items-start gap-[24px]">
        <TotalEarned total={total} />
        <div className="relative flex sm:hidden items-center gap-[16px]">
          <div className="text-[#A0A4AC]">Sort by:</div>
          <button
            type="button"
            className="text-[16px] font-medium flex items-center gap-[12px]"
            onClick={() => setSortOpen(!sortOpen)}
          >
            {selectedSort}
            <motion.div
              style={{
                height: '5px',
                aspectRatio: '2',
                clipPath: 'polygon(50% 100%,100% 0,0 0)',
                background: '#000',
                rotate: 180,
              }}
              animate={{rotate: sortOpen ? 0 : 180}}
              transition={{
                duration: 0.15,
                ease: 'easeInOut',
              }}
            />
          </button>
          {sortOpen && (
            <AnimatePresence>
              <SortDialog
                position="top-8 -right-2"
                setIsOpen={setSortOpen}
                selectedSort={selectedSort}
                setSelectedSort={sort =>
                  setSelectedSort(sort as 'Lowest amount' | 'Highest amount')
                }
              />
            </AnimatePresence>
          )}
        </div>
        <ReferralList sorting={selectedSort} />
      </div>
    </>
  );
};

export default ReferralsView;
