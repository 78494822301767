import {tokens} from '../../../constants';
import {formatNumberWithFixedDecimals} from 'lib/formatNum';
import {useEffect, useState} from 'react';
import {Price} from 'services/price';
import {Icons} from 'ui-kit/Icons';

const PONZI_TO_USD = 0.0003676;

const TotalEarned = ({total}: {total: number}) => {
  const [depositUSD, setDepositInUSD] = useState(total * PONZI_TO_USD);

  useEffect(() => {
    if (isNaN(total) || total <= 0) return;

    const solPrice = Price.get(tokens['production']);
    setDepositInUSD(total * Number(solPrice));
  }, [total]);

  return (
    <div className="p-[24px] bg-white rounded-[16px] w-full sm:max-w-[274px]">
      <div className="flex items-center justify-between">
        <div className="text-[18px] leading-[28px]">Total earned</div>
        <div>
          <Icons.Solana />
        </div>
      </div>
      <div className="text-[28px] mt-[35px]">
        {formatNumberWithFixedDecimals(total, 4)} PONZI
      </div>
      <div className="text-[#A0A4AC] text-[14px] leading-[21px]">
        / {formatNumberWithFixedDecimals(depositUSD, 5)}$
      </div>
    </div>
  );
};

export default TotalEarned;
