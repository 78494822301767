import {
  SliderRange,
  Root as SliderRoot,
  SliderThumb,
  SliderTrack,
} from '@radix-ui/react-slider';
import {ReactNode, forwardRef} from 'react';
import {ControllerRenderProps} from 'react-hook-form';
import './styles.css';

interface FormRangeProps extends Omit<ControllerRenderProps, 'ref'> {
  label: ReactNode;
  min: number;
  max: number;
  step?: number;
  minLabel?: string;
  maxLabel?: string;
  tooltipPostfix?: string;
}

export const FormRange = forwardRef<HTMLInputElement, FormRangeProps>(
  (
    {label, min, max, step = 1, minLabel, maxLabel, tooltipPostfix, ...props},
    ref
  ) => {
    return (
      <label className="flex flex-col gap-2">
        <span className="text-sm">{label}</span>
        <span className="flex items-end">
          <SliderRoot
            className="relative flex items-center w-full h-5 cursor-pointer"
            ref={ref}
            min={min}
            max={max}
            step={step}
            defaultValue={[props.value]}
            onValueChange={value => props.onChange(value[0])}
          >
            <SliderTrack className="relative grow h-1 rounded-full bg-[#F9F9F9]">
              <SliderRange className="absolute h-full rounded-full bg-black" />
            </SliderTrack>
            <SliderThumb className="flex justify-center w-5 h-5 rounded-full border-4 border-black bg-white outline-none">
              <span className="form-range-tooltip gap-1">
                <div>{props.value}</div>
                <div>{tooltipPostfix}</div>
              </span>
            </SliderThumb>
          </SliderRoot>
        </span>
        <span className="flex justify-between items-center text-sm text-black text-opacity-50">
          <span>{minLabel}</span>
          <span>{maxLabel}</span>
        </span>
      </label>
    );
  }
);
