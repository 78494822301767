import {CoinbaseWallet} from '@web3-react/coinbase-wallet';
import {MetaMask} from '@web3-react/metamask';
import {Connector} from '@web3-react/types';
import {WalletConnect} from '@web3-react/walletconnect-v2';
import {Provider, SolanaProvider} from './types';
import {WalletName} from '@solana/wallet-adapter-base';
import {
  OntoWalletName,
  PhantomWalletName,
  SaifuWalletName,
  SolflareWalletName,
} from '@solana/wallet-adapter-wallets';

export function getProviderByConnector(connector: Connector): Provider {
  if (connector instanceof MetaMask) return 'MetaMask';
  if (connector instanceof WalletConnect) return 'WalletConnect';
  if (connector instanceof CoinbaseWallet) return 'Coinbase';
  throw new Error('Unsupported connector');
}

export function getSolanaProvider(adapter: string): SolanaProvider {
  switch (adapter) {
    case 'Phantom':
      return adapter;
    case 'Solflare':
      return adapter;
    case 'Saifu':
      return adapter;
    case 'Onto':
      return adapter;
  }
  throw new Error('Failed to get solana provider by string');
}

export function getSolanaWalletName(provider: SolanaProvider): WalletName {
  switch (provider) {
    case 'Phantom':
      return PhantomWalletName;
    case 'Solflare':
      return SolflareWalletName;
    case 'Saifu':
      return SaifuWalletName;
    case 'Onto':
      return OntoWalletName;
  }
}
