import axios, {AxiosInstance, InternalAxiosRequestConfig} from 'axios';
import {API_BASE_URL} from '../constants';
import {AuthRefreshResponse, AuthResponse} from 'types/Auth';

interface IUserResponse {
  id: string;
  wallet: string;
  createdAt: string; // Note: Date comes as string from API
}

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  return config;
};

export class AuthApi {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_BASE_URL,
      headers: {'Content-Type': 'application/json'},
      withCredentials: true,
    });

    this._api.defaults.headers.put['Content-Type'] = 'application/json';
    this._api.interceptors.request.use((config: InternalAxiosRequestConfig) =>
      requestInterceptor(config)
    );
  }

  async sendSignature(
    wallet: string,
    signature: string | Uint8Array,
    network: 'sol' | 'arb' = 'sol'
  ): Promise<AuthResponse | null> {
    try {
      const url = `/api/auth`
      let signature_ : string[] | undefined;

      if (network === 'sol') {
        signature_ = Array.from(signature as any).map(s => String(s));
      }

      const res = await this._api.post<AuthResponse>(url, {
        wallet,
        signature: signature_ || signature,
        network
      });

      localStorage.setItem('token', res.data.tokens.access);

      return res.data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async refresh(isSafe = false): Promise<AuthRefreshResponse | null> {
    try {
      const url = '/api/auth/refresh';
      const res = await this._api.post<AuthRefreshResponse>(url);

      localStorage.setItem('token', res.data.access);

      return res.data;
    } catch (error) {
      localStorage.removeItem('token');
      console.warn(error);
      if (!isSafe) {
        throw error;
      }
    }
    return null;
  }

  async getUserById(userId: string) {
    try {
       const { data } = await this._api.get<IUserResponse>(`/api/users/${userId}`);
    return data;
    } catch (e) {
      console.warn(e);
    }
    return null;
  }

}

export const authApi = new AuthApi();
