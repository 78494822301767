import {MouseEvent, ReactNode, useRef, useState} from 'react';
import {Game} from 'types/Game';
import noImage from 'assets/no-image.png';
import {useSearchParams} from 'react-router-dom';
import './styles.css';

interface GridValue {
  label: string;
  value: ReactNode;
}

export interface GameCardLgProps {
  imgSrc: string;
  gridValues: [GridValue, GridValue, GridValue, GridValue, GridValue];
  children?: ReactNode;
  action?: ReactNode;
  game: Game;
  setGame: (game: Game) => void;
}

export function GameCardLg({
  imgSrc,
  gridValues,
  children,
  action,
  game,
  setGame,
}: GameCardLgProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  if (gridValues.length !== 5) {
    throw new Error('gridValues must have a length of 5');
  }

  const handleClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;

    // Check if clicked element or its parents have click handlers
    let element: HTMLElement | null = target;
    while (element && element !== containerRef.current) {
      // Check for click handlers
      const hasClickHandler =
        element.onclick !== null ||
        element.tagName === 'BUTTON' ||
        element.closest('button') !== null;

      if (hasClickHandler) {
        return; // Exit if we found an element with click handler
      }
      element = element.parentElement;
    }

    // If we get here, no click handlers were found
    // Proceed with the card click behavior
    if (!containerRef.current?.contains(target)) {
      return;
    }

    setGame(game);
    searchParams.set('gameId', game.id);
    setSearchParams(searchParams);
  };

  return (
    <>
      <div
        className="game-card-lg cursor-pointer"
        onClick={handleClick}
        ref={containerRef}
      >
        <img
          src={imgSrc.length > 0 ? imgSrc : noImage}
          alt=""
          className="game-card-lg-image"
        />
        <div className="flex relative flex-col gap-6 justify-between items-center w-full h-full p-5 pb-3 min-[1380px]:gap-0 min-[1380px]:flex-row 2xl:p-6">
          <div className="flex flex-col justify-between items-start self-stretch">
            {children}
          </div>
          <div className="grid grid-rows-2 grid-cols-3 w-full min-[1380px]:w-auto min-[1380px]:grid-cols-[110px_42px_82px] gap-x-4 gap-y-4 xl:gap-x-8 xl:mx-auto">
            {gridValues.map(({value, label}, index) => (
              <div
                key={index}
                className="flex flex-col gap-2 text-xs md:text-sm last:col-span-2"
              >
                <p className="text-[0.625rem] md:text-xs text-[#A0A4AC]">
                  {label}
                </p>
                {value}
              </div>
            ))}
          </div>
          <div className="w-full mt-auto md:mt-0 lg:max-w-[200px] rounded-full">
            {action}
          </div>
        </div>
      </div>
    </>
  );
}
