import {getMint} from '@solana/spl-token';
import {PublicKey} from '@solana/web3.js';
import {connection} from '../constants';
import {Price} from 'services/price';
import {Coin} from 'types';
import {Bet, BetStatus} from 'types/Game';

/**
 * Calculate timestamp of end of the game
 * @description Game ends 10 minutes after the last bet
 * @param bets array of bets
 * @returns timestamp of end of the game
 */
export const calcEndOfGame = (bets: Bet[]) =>
  new Date(bets[bets.length - 1].createdAt).getTime() + 1000 * 60 * 10;

/**
 * Calculate profit for host of the game
 * @param hardPool
 * @param softPool
 * @param hostFee
 * @param platformFee
 */
export const calcProfit = (
  hardPool: number,
  softPool: number,
  hostFee: number,
  platformFee: number
) => (((hardPool + softPool) / 100) * (hostFee + platformFee)) / 2;

export const getTokenDecimals = async (
  addr: string
): Promise<number | undefined> => {
  // if (localStorage.getItem(addr)) {
  //   return Number(localStorage.getItem(addr));
  // }
  // const pk = new PublicKey(addr);
  // const tokenAccountInfo = await getMint(connection, pk);
  // if (tokenAccountInfo.decimals) {
  //   localStorage.setItem(addr, tokenAccountInfo.decimals.toString());
  // }
  return 6;
};

export const getCoin = async (addr: string): Promise<Coin | undefined> => {
  try {
    const coins = await Price.fetchCoins();
    return coins.find(coin => coin.address === addr);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const getJackpotAmountToWithdraw = (bets: Bet[]): number => {
  const totalBetsAmount = bets.reduce((accumulator, currentValue) => {
    return accumulator + Number(currentValue.amount);
  }, 0);
  // to subtract from totalBets
  const totalAmountAvailableToClaim = bets.reduce(
    (accumulator, currentValue) => {
      if (
        currentValue.status !== BetStatus.PENDING &&
        currentValue.status !== BetStatus.JACKPOT_WITHDRAW_AVAILABLE
      ) {
        return accumulator + Number(currentValue.amountForWithdraw);
      }
      return accumulator;
    },
    0
  );

  const res = totalBetsAmount - totalAmountAvailableToClaim;

  console.log('Total bets amount: ', totalBetsAmount);
  console.log('Total amount to claim: ', totalAmountAvailableToClaim);
  console.log('Result: ', res);

  return Number(res);
};

/**
 * Calculate profit for platform of the game
 *
 * Filter is used before sorting to reduce the number of iterations
 *
 * @param bets array of bets
 * @param account address of the user
 */
export const getBetForWithdraw = (
  bets: Bet[],
  account: string
): Bet | undefined => {
  const myBetsForWithdraw = bets.filter(
    bet =>
      (bet.status === BetStatus.WITHDRAW_AVAILABLE ||
        bet.status === BetStatus.JACKPOT_WITHDRAW_AVAILABLE) &&
      bet.user.wallet.toLowerCase() === account.toLowerCase()
  );
  console.log(
    'My bets for withdraw: ',
    myBetsForWithdraw.filter(
      b => b.betId === 4 || b.betId === 1 || b.betId === 2
    )
  );
  return getSortedBets(myBetsForWithdraw)?.[0];
};

export const getSortedBets = (bets: Bet[]) => {
  return bets.sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });
};
