import {useState, useRef} from 'react';
import {Coin} from 'types';

interface FormDropdownProps {
  label: string;
  options: Coin[];
  selected: string;
  selectOption: (icon: string, addr: string, symbol: string) => void;
}

export const FormDropdown = ({
  label,
  options,
  selected,
  selectOption,
}: FormDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedOption = options.find(c => c.address === selected);
  const dropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div className="relative">
      <label className="block text-sm mb-1">{label}</label>
      <div
        className="flex items-center gap-2 px-4 py-3 bg-[#F9F9F9] rounded-xl cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption && (
          <img
            src={selectedOption.image}
            alt={selectedOption.name}
            className="w-6 h-6 rounded-full"
          />
        )}
        <span className="text-[14px]">
          {selectedOption ? selectedOption.name : 'Select an option'}
        </span>
      </div>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="absolute left-0 mt-2 w-full bg-white rounded-lg shadow-lg z-50 max-h-[145px] overflow-auto"
        >
          {options.map(option => (
            <div
              key={option.address}
              className="flex items-center gap-2 px-4 py-3 cursor-pointer hover:bg-gray-100"
              onClick={() => {
                selectOption(option.image, option.address, option.symbol);
                setIsOpen(false);
              }}
            >
              <img
                src={option.image}
                alt={option.name}
                className="w-6 h-6 rounded-full"
              />
              <span className="text-[14px]">{option.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
