import {useEffect, useMemo} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {FormField} from 'ui-kit/FormField';
import {FormMessage} from 'ui-kit/FormMessage';
import {FormRange} from 'ui-kit/FormRange';
import {Icons} from 'ui-kit/Icons';
import {NewGameFormScheme, NewGameFormType, refiners} from './scheme';
import {NewGameStep} from './NewGameStep1';
import {Tooltip} from 'ui-kit/Tooltip';
import {useWallet} from '@solana/wallet-adapter-react';
import {FieldSolanaIcon} from 'ui-kit/FieldSolanaIcon';

export function NewGameStep2() {
  const {connected} = useWallet();
  const formCtx = useFormContext<NewGameFormType>();
  const minDeposit = formCtx.watch('minDeposit');
  const maxDeposit = formCtx.watch('maxDeposit');
  const roi = formCtx.watch('roi');
  const fee = formCtx.watch('fee');

  const calcMinDeposit = Math.ceil((minDeposit * 200) / roi);

  const isInvalid = useMemo(() => {
    if (calcMinDeposit >= maxDeposit) {
      return true;
    }
    return (
      NewGameFormScheme(connected ? 'sol' : 'arb')
        .pick({
          minDeposit: true,
          maxDeposit: true,
          roi: true,
          fee: true,
        })
        .refine(refiners.minMaxDeposit)
        .safeParse({
          minDeposit,
          maxDeposit,
          roi,
          fee,
        }).success === false
    );
  }, [minDeposit, maxDeposit, roi, fee]);

  const handlePrev = () => {
    formCtx.resetField('minDeposit');
    formCtx.resetField('maxDeposit');
    formCtx.resetField('roi');
    formCtx.resetField('fee');
    formCtx.setValue('step', 1);
  };

  const handleNext = () => {
    if (isInvalid) return;
    formCtx.setValue('step', 3);
  };
  return (
    <NewGameStep step={2} steps={4} title="Game settings">
      <div className="flex flex-col -mb-8 gap-6 w-[calc(100vw-5rem)] sm:w-[406px]">
        <div className="grid gap-2">
          <FormField
            type="number"
            label="Minimum deposit limit"
            placeholder={`1 ${formCtx.getValues('coinSymbol') ?? 'PONZI'}`}
            leftIcon={
              formCtx.getValues('coinIcon') ? (
                <img
                  width={20}
                  height={20}
                  src={formCtx.getValues('coinIcon')}
                  alt="Coin icon"
                  className="rounded-full"
                />
              ) : (
                <FieldSolanaIcon />
              )
            }
            min={connected ? 1 : 0.001}
            max={maxDeposit ? maxDeposit : connected ? 1000000 : 1000}
            {...formCtx.register('minDeposit', {
              valueAsNumber: true,
            })}
          />
          <FormMessage name="minDeposit" />
        </div>
        <div className="grid gap-2">
          <FormField
            type="number"
            label="Maximum deposit limit"
            placeholder={`1000000 ${formCtx.getValues('coinSymbol') ?? 'PONZI'}`}
            leftIcon={
              formCtx.getValues('coinIcon') ? (
                <img
                  width={20}
                  height={20}
                  src={formCtx.getValues('coinIcon')}
                  alt="Coin icon"
                  className="rounded-full"
                />
              ) : (
                <FieldSolanaIcon />
              )
            }
            min={minDeposit ? minDeposit : connected ? 1 : 0.001}
            max={connected ? 1000000 : 1000}
            {...formCtx.register('maxDeposit', {
              valueAsNumber: true,
            })}
          />
          <FormMessage name="maxDeposit" />
        </div>
        <div className="grid gap-2">
          <Controller
            name="roi"
            control={formCtx.control}
            render={({field}) => (
              <FormRange
                label="ROI %"
                min={1}
                max={100}
                minLabel="1%"
                maxLabel="100%"
                tooltipPostfix="%"
                {...field}
              />
            )}
          />
          <FormMessage name="roi" />
        </div>
        <div className="grid gap-2">
          <Controller
            name="fee"
            control={formCtx.control}
            render={({field}) => (
              <FormRange
                label={
                  <span className="flex items-center gap-2">
                    <span>Game fee %</span>
                    <Tooltip
                      content={
                        <>
                          <span className="text-black">Fees explanation</span>
                          You will get 50% of the game fee automatically
                        </>
                      }
                    >
                      <Icons.Info />
                    </Tooltip>
                  </span>
                }
                minLabel="2%"
                maxLabel="10%"
                tooltipPostfix="%"
                min={2}
                max={10}
                step={1}
                {...field}
              />
            )}
          />
          <FormMessage name="fee" />
        </div>
        <div className="grid gap-2 mb-4">
          <Controller
            name="duration"
            control={formCtx.control}
            render={({field}) => (
              <FormRange
                label={
                  <span className="flex items-center gap-2">Game duration</span>
                }
                minLabel="1 Minute"
                maxLabel="600 Minutes"
                tooltipPostfix={
                  formCtx.getValues('duration') === 1 ? 'Minute' : 'Minutes'
                }
                min={1}
                max={600}
                step={1}
                {...field}
              />
            )}
          />
          <FormMessage name="fee" />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center -mb-3 sm:-mb-6 gap-6">
        <button className="btn" disabled={isInvalid} onClick={handleNext}>
          Next
          <Icons.ArrowRight />
        </button>
        <button className="flex items-center gap-4" onClick={handlePrev}>
          <div className="rotate-180">
            <Icons.ArrowRight color="black" />
          </div>
          Previous step
        </button>
      </div>
    </NewGameStep>
  );
}
