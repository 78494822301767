import {useWeb3React} from '@web3-react/core';
import {useAllGamesStore} from 'screens/AllGames/store';
import {gameService} from 'services/games';
import {Game, GameStatus} from 'types/Game';
import {EthereumValue} from 'ui-kit/EthereumValue';
import {SolanaValue} from 'ui-kit/SolanaValue';
import {GameButton} from 'ui-kit/GameButton';
import {GameCardLg} from 'ui-kit/GameCardLg';
import {useWallet} from '@solana/wallet-adapter-react';
import CopyButton from 'ui-kit/CopyButton';
import {useEffect, useState} from 'react';
import {getCoin, getTokenDecimals} from 'utils/game';
import {Coin} from 'types';
import './styles.css';

const SHARE_URL =
  process.env.REACT_APP_SHARE_URL || 'https://share.ponzi.market';

export interface GameCardLgProps {
  game: Game;
  setGame: (game: Game) => void;
}

export function AllGameCard({game, setGame: setGameDialog}: GameCardLgProps) {
  const {
    name,
    createdAt,
    thumbnailUrl,
    softPool,
    roi,
    minDeposit,
    maxDeposit,
    hostFee,
    platformFee,
    bets,
    currencyAddress,
  } = game;
  const numberOfDeposits = bets?.length || 0;
  const setGame = useAllGamesStore(s => s.setGame);
  const date = new Date(createdAt).toLocaleDateString('en-UK', {
    day: 'numeric',
    month: 'short',
  });

  const time = new Date(createdAt).toLocaleTimeString('en-UK', {
    hour: 'numeric',
    minute: 'numeric',
  });

  const [coin, setCoin] = useState<Coin | undefined>(undefined);
  const [decimals, setDecimals] = useState<number>(6);
  useEffect(() => {
    const retreiveIcon = async () => {
      const c = await getCoin(currencyAddress);
      if (c) {
        setCoin(c);
      }
      const d = await getTokenDecimals(currencyAddress);
      if (d) setDecimals(d);
    };
    retreiveIcon().catch(console.error);
  }, []);

  const {connected} = useWallet();
  const {isActive} = useWeb3React();
  const [icon, setIcon] = useState<'link' | 'check'>('link');
  const {publicKey} = useWallet();

  let active = false;

  if (connected) {
    active = true;
  } else if (isActive) {
    active = true;
  }

  const handleGameRefetch = async () => {
    if (!active) return;
    const res = await gameService.getGameById(
      game.id,
      connected ? 'sol' : 'arb'
    );
    if (!res) return;

    setGame(res);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${SHARE_URL}/?gameId=${game.id}&ref=${publicKey}`
    );
    setIcon('check');
    setTimeout(() => {
      setIcon('link');
    }, 2000);
  };

  return (
    <GameCardLg
      setGame={setGameDialog}
      game={game}
      imgSrc={thumbnailUrl}
      gridValues={[
        {
          label: 'Current jackpot',
          value: (
            <SolanaValue
              symbol={coin?.symbol}
              icon={coin?.image}
              value={Number(softPool) / 10 ** decimals}
            />
          ),
        },
        {
          label: 'ROI',
          value: `${roi}%`,
        },
        {
          label: 'Fee',
          value: `${hostFee + platformFee}%`,
        },
        {
          label: 'Min. limit',
          value: connected ? (
            <SolanaValue
              symbol={coin?.symbol}
              icon={coin?.image}
              value={minDeposit}
            />
          ) : (
            <EthereumValue value={minDeposit} />
          ),
        },
        {
          label: 'Max. limit',
          value: connected ? (
            <SolanaValue
              symbol={coin?.symbol}
              icon={coin?.image}
              value={maxDeposit}
            />
          ) : (
            <EthereumValue value={maxDeposit} />
          ),
        },
      ]}
      action={
        <div className="flex flex-col items-center gap-4 pb-1 md:pb-0">
          <GameButton
            status={GameStatus.AWAITING_DEPOSIT}
            game={game}
            refetchGame={() => {
              handleGameRefetch().catch(console.warn);
            }}
            numberOfDeposits={numberOfDeposits}
          />
          {/*<div className="text-sm font-medium hidden xl:block">*/}
          {/*  {`${numberOfDeposits} ${*/}
          {/*    numberOfDeposits === 1 ? 'deposit' : 'deposits'*/}
          {/*  }`}*/}
          {/*</div>*/}
        </div>
      }
    >
      <span className="block mb-2 game-card-lg-createdAt lg:hidden">
        <span className="game-card-lg-muted">Created</span> {date} at {time}
      </span>
      <div className="flex w-full justify-between items-center gap-2 h-full lg:w-auto lg:flex-col lg:items-start">
        <div className="flex flex-col items-start gap-2">
          <h5 className="w-[150px] text-base line-clamp-2 lg:w-full min-[1380px]:w-[300px] md:text-lg lg:line-clamp-1">
            {name}
          </h5>
          <span className="hidden game-card-lg-createdAt lg:block">
            <span className="game-card-lg-muted">Created</span> {date} at {time}
          </span>
        </div>
        <div className="flex gap-2 items-center">
          <div className="items-center gap-[8px] hidden xl:flex">
            <CopyButton icon={icon} handleCopy={handleCopy} />
            <div className="text-[#A1A4AB] text-[14px] text-center">
              Share to get <span className="text-black">25%</span> of the game
              fee
            </div>
          </div>
          <div className="text-sm font-medium block xl:hidden">
            {`${numberOfDeposits} ${
              numberOfDeposits === 1 ? 'deposit' : 'deposits'
            }`}
          </div>
        </div>
      </div>
    </GameCardLg>
  );
}
