import {DialogTitle} from '@radix-ui/react-dialog';
import {
  getHasMetaMaskExtensionInstalled,
  tryActivate,
  tryDeactivate,
} from 'lib/web3-react/connections';
import {PRIORITIZED_CONNECTORS} from 'lib/web3-react/constants';
import {Provider, SolanaProvider} from 'lib/web3-react/types';
import {useState} from 'react';
import {useDashboardStore} from 'screens/Dashboard/store/store';
import {WalletButton} from './WalletButton';
import {useWallet} from '@solana/wallet-adapter-react';
import {getSolanaWalletName} from 'lib/web3-react/utils';
import {useWeb3React} from '@web3-react/core';

const phantomIcon = require('../../../../assets/wallets/phantom.png') as string;
const saifuIcon = require('../../../../assets/wallets/saifu.png') as string;
const ontoIcon = require('../../../../assets/wallets/onto.png') as string;
const solflareIcon =
  require('../../../../assets/wallets/solflare.png') as string;

const metamaskIcon =
  require('../../../../assets/wallets/metaMask.png') as string;
const walletConnectIcon =
  require('../../../../assets/wallets/walletConnect.png') as string;
const coinbaseIcon =
  require('../../../../assets/wallets/coinbase.png') as string;

interface ConnectWalletDialogContentProps {
  onWalletConnect: () => void;
  onLockChange?: (isLocked: boolean) => void;
}

export function ConnectWalletDialogContent({
  onWalletConnect,
  onLockChange,
}: ConnectWalletDialogContentProps) {
  const {select, connected, connecting, wallet} = useWallet();
  const {isActive, connector} = useWeb3React();

  const [isConnecting, setIsConnecting] = useState(false);
  const addToast = useDashboardStore(state => state.addToast);

  const isMetaMaskInstalled = getHasMetaMaskExtensionInstalled();

  const handleWalletConnect = (provider: Provider) => {
    if (connected) {
      try {
        wallet?.adapter.disconnect();
      } catch (e) {
        console.error(e);
        return;
      }
    }

    if (provider === 'MetaMask' && !isMetaMaskInstalled) {
      window.open(
        `https://metamask.app.link/dapp/${window.location.href}`,
        '_self'
      );
    }

    setIsConnecting(true);
    onLockChange?.(true);
    tryActivate(PRIORITIZED_CONNECTORS[provider].connector)
      .then(() => {
        addToast({
          status: 'success',
          children: 'Your wallet has been connected',
        });

        onWalletConnect();
      })
      .catch(() => console.warn(`Failed to connect to ${provider}`))
      .finally(() => {
        setIsConnecting(false);
        onLockChange?.(false);
      });
  };

  const handleSolanaWalletConnect = async (provider: SolanaProvider) => {
    if (isActive) {
      try {
        if (!connector) throw new Error('No connector');
        tryDeactivate(connector).catch(() => {
          console.warn('Failed to disconnect wallet');
        });
      } catch (error) {
        console.error(error);
      }
    }
    if (connected || connecting) {
      if (wallet?.adapter.toString() === provider) {
        return;
      }
      try {
        wallet?.adapter.disconnect();
      } catch (e) {
        console.error(e);
        return;
      }
    }

    try {
      const walletName = getSolanaWalletName(provider);
      setIsConnecting(true);
      onLockChange?.(true);
      select(walletName); // somehow, select establishes the conn
      addToast({
        status: 'success',
        children: 'Your wallet has been connected',
      });
      onWalletConnect();
    } catch (e) {
      console.warn('Failed to select wallet');
      console.error(e);
      return;
    }
    setIsConnecting(false);
    onLockChange?.(false);
  };

  return (
    <div className="flex flex-col items-center w-[295px] sm:w-[543px] gap-[50px] p-5 pt-10 sm:p-[60px] sm:pb-[50px]">
      <DialogTitle className="dialog-title sm:leading-9">
        Connect your wallet
      </DialogTitle>
      <div className="flex flex-col items-center gap-4 w-full">
        {/*<WalletButton*/}
        {/*  disabled={isConnecting}*/}
        {/*  leftIcon={*/}
        {/*    <img*/}
        {/*      src={metamaskIcon}*/}
        {/*      alt="Metamask"*/}
        {/*      className="w-[22px] h-[22px]"*/}
        {/*    />*/}
        {/*  }*/}
        {/*  onClick={() => handleWalletConnect('MetaMask')}*/}
        {/*>*/}
        {/*  Metamask*/}
        {/*</WalletButton>*/}
        {/*<WalletButton*/}
        {/*  disabled={isConnecting}*/}
        {/*  leftIcon={*/}
        {/*    <img*/}
        {/*      src={walletConnectIcon}*/}
        {/*      alt="WalletConnect"*/}
        {/*      className="w-[22px] h-[22px]"*/}
        {/*    />*/}
        {/*  }*/}
        {/*  onClick={() => handleWalletConnect('WalletConnect')}*/}
        {/*>*/}
        {/*  WalletConnect*/}
        {/*</WalletButton>*/}
        {/*<WalletButton*/}
        {/*  disabled={isConnecting}*/}
        {/*  leftIcon={*/}
        {/*    <img*/}
        {/*      src={coinbaseIcon}*/}
        {/*      alt="Coinbase"*/}
        {/*      className="w-[22px] h-[22px]"*/}
        {/*    />*/}
        {/*  }*/}
        {/*  onClick={() => handleWalletConnect('Coinbase')}*/}
        {/*>*/}
        {/*  Coinbase*/}
        {/*</WalletButton>*/}
        {/*<WalletButton*/}
        {/*  disabled={isConnecting}*/}
        {/*  leftIcon={*/}
        {/*    <img src={ontoIcon} alt="Onto" className="w-[22px] h-[22px]" />*/}
        {/*  }*/}
        {/*  onClick={() => handleSolanaWalletConnect('Onto')}*/}
        {/*>*/}
        {/*  Onto*/}
        {/*</WalletButton>*/}
        <WalletButton
          disabled={isConnecting}
          leftIcon={
            <img
              src={phantomIcon}
              alt="Phantom"
              className="w-[22px] h-[22px]"
            />
          }
          onClick={() => handleSolanaWalletConnect('Phantom')}
        >
          Phantom
        </WalletButton>
        {/*<WalletButton*/}
        {/*  disabled={isConnecting}*/}
        {/*  leftIcon={*/}
        {/*    <img src={saifuIcon} alt="Saifu" className="w-[22px] h-[22px]" />*/}
        {/*  }*/}
        {/*  onClick={() => handleSolanaWalletConnect('Saifu')}*/}
        {/*>*/}
        {/*  Saifu*/}
        {/*</WalletButton>*/}
        <WalletButton
          disabled={isConnecting}
          leftIcon={
            <img
              src={solflareIcon}
              alt="Solflare"
              className="w-[22px] h-[22px]"
            />
          }
          onClick={() => handleSolanaWalletConnect('Solflare')}
        >
          Solflare
        </WalletButton>
      </div>
    </div>
  );
}
