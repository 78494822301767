import {JsonRpcSigner, TransactionResponse} from '@ethersproject/providers';
import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from 'axios';
import {gameABI} from 'constants/gameAbi';
import {gameFactoryABI} from 'constants/gameFactoryAbi';
import {BigNumberish, ethers} from 'ethers';
import {allGamesStore} from 'screens/AllGames';
import {hostedGamesStore} from 'screens/HostedGames/store';
import {myGamesStore} from 'screens/MyGames/store';
import {
  Bet,
  BetStatus,
  CreateGameParams,
  CreateGameParamsSol,
  CreateGameParamsSolInit,
  Game,
  GameRequestOptions,
  GamesResponse,
  SortQueryOptions,
  WithdrawResponse,
} from 'types/Game';
import {API_BASE_URL, GAME_CONTRACTS, GAMES_PER_PAGE} from '../constants';
import {Transaction} from '@solana/web3.js';
import {authApi} from './auth';
import {getTokenDecimals} from 'utils/game';

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  return config;
};

export class GameService {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_BASE_URL,
      headers: {'Content-Type': 'application/json'},
      withCredentials: true,
    });

    this._api.defaults.headers.put['Content-Type'] = 'application/json';
    this._api.interceptors.request.use((config: InternalAxiosRequestConfig) =>
      requestInterceptor(config)
    );
  }

  async getAllGames({
    limit = GAMES_PER_PAGE,
    addToExisting = false,
    search = '',
    network = 'sol',
    from, // walletaddr
    sort = SortQueryOptions.NEWEST,
    ...filters
  }: GameRequestOptions = {}): Promise<Game[]> {
    try {
      // sol network hardcoded. arb is not supported anymore
      network = 'sol';
      allGamesStore.setState({isLoading: !addToExisting});
      const url = `/api/games?network=${network}`;
      const {games, page} = allGamesStore.getState();

      const fromDateEncoded = encodeURIComponent(
        filters.fromDate?.toString().replace(/\s\([^)]+\)$/, '') || ''
      );
      const toDateEncoded = encodeURIComponent(
        filters.toDate?.toString().replace(/\s\([^)]+\)$/, '') || ''
      );
      const {data} = await this._api.get<GamesResponse>(url, {
        params: {
          page,
          limit,
          sort,
          search,
          from,

          ...(filters.fromDate && {
            fromDate: fromDateEncoded,
          }),
          ...(filters.toDate && {
            toDate: toDateEncoded,
          }),
          fromJackpot: filters.fromJackpot,
          toJackpot: filters.toJackpot,
          fromBet: filters.fromBet,
          toBet: filters.toBet,
          fromMinute: filters.fromMinute,
          toMinute: filters.toMinute,
        },
      });

      const newGames = addToExisting ? [...games, ...data.data] : data.data;
      allGamesStore.setState({
        games: newGames,
        hasMore: data.data.length === limit && data.total > newGames.length,
        totalGames: data.total,
      });

      return data.data;
    } catch (error) {
      console.warn(error);
    } finally {
      allGamesStore.setState({isLoading: false});
    }
    return [];
  }

  async getMyGames({
    limit = GAMES_PER_PAGE,
    addToExisting = false,
    search = '',
    network = 'sol',
    sort = SortQueryOptions.NEWEST,
    ...filters
  }: GameRequestOptions = {}): Promise<Game[]> {
    try {
      myGamesStore.setState({isLoading: !addToExisting});
      const {games, page} = myGamesStore.getState();
      const url = `/api/games/my?network=${network}`;

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const fromDateEncoded = encodeURIComponent(
        filters.fromDate?.toString().replace(/\s\([^)]+\)$/, '') || ''
      );
      const toDateEncoded = encodeURIComponent(
        filters.toDate?.toString().replace(/\s\([^)]+\)$/, '') || ''
      );
      console.log(filters.toDate);
      const {data} = await this._api.get<GamesResponse>(url, {
        params: {
          page,
          limit,
          search,
          sort,

          ...(filters.fromDate && {
            fromDate: fromDateEncoded,
          }),
          ...(filters.toDate && {
            toDate: toDateEncoded,
          }),
          fromJackpot: filters.fromJackpot,
          toJackpot: filters.toJackpot,
          fromBet: filters.fromBet,
          toBet: filters.toBet,
          fromMinute: filters.fromMinute,
          toMinute: filters.toMinute,
        },
        headers: {
          authorization: token,
        },
      });

      const newGames = addToExisting ? [...games, ...data.data] : data.data;

      myGamesStore.setState({
        games: newGames,
        hasMore: data.data.length === limit && data.total > newGames.length,
        totalGames: data.total,
      });

      return data.data;
    } catch (error) {
      console.warn(error);
    } finally {
      myGamesStore.setState({isLoading: false});
    }
    return [];
  }

  async getHostedGames({
    limit = GAMES_PER_PAGE,
    addToExisting = false,
    search = '',
    network = 'sol',
    sort = SortQueryOptions.NEWEST,
    ...filters
  }: GameRequestOptions = {}): Promise<Game[]> {
    try {
      hostedGamesStore.setState({isLoading: !addToExisting});
      const {games, page} = hostedGamesStore.getState();
      const url = `/api/games/hosted?network=${network}`;

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const fromDateEncoded = encodeURIComponent(
        filters.fromDate?.toString().replace(/\s\([^)]+\)$/, '') || ''
      );
      const toDateEncoded = encodeURIComponent(
        filters.toDate?.toString().replace(/\s\([^)]+\)$/, '') || ''
      );
      const {data} = await this._api.get<GamesResponse>(url, {
        params: {
          sort,
          page,
          limit,
          search,

          ...(filters.fromDate && {
            fromDate: fromDateEncoded,
          }),
          ...(filters.toDate && {
            toDate: toDateEncoded,
          }),
          fromJackpot: filters.fromJackpot,
          toJackpot: filters.toJackpot,
          fromBet: filters.fromBet,
          toBet: filters.toBet,
          fromMinute: filters.fromMinute,
          toMinute: filters.toMinute,
        },
        headers: {
          authorization: token,
        },
      });

      const newGames = addToExisting ? [...games, ...data.data] : data.data;
      hostedGamesStore.setState({
        games: newGames,
        hasMore: data.data.length === limit && data.total > newGames.length,
        totalGames: data.total,
      });

      return data.data;
    } catch (error) {
      console.warn(error);
    } finally {
      hostedGamesStore.setState({isLoading: false});
    }
    return [];
  }

  async getGameInfoById(id: Game['id']) {
    const url = `/api/games/info/${id}`;
    const {data} = await this._api.get<Game>(url);
    return data;
  }

  async getGameById(
    id: Game['id'],
    network: 'sol' | 'arb' = 'sol'
  ): Promise<Game | null> {
    try {
      const url = `/api/games/${id}?network=${network}`;
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const {data} = await this._api.get<Game>(url, {
        headers: {
          authorization: token,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async getMaxJackpot(): Promise<{maxJackpot: number} | null> {
    try {
      const url = `/api/games/max-jackpot`;
      const {data} = await this._api.get<{maxJackpot: number}>(url);

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async getMaxBet(): Promise<{maxBet: number} | null> {
    try {
      const url = `/api/games/max-bet`;
      const {data} = await this._api.get<{maxBet: number}>(url);

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async uploadFile(fileList: FileList): Promise<string | null> {
    try {
      const url = `/api/files/upload`;
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const {data} = await this._api.post<string, {data: string}>(
        url,
        {file: fileList[0]},
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: token,
          },
        }
      );

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  /**
   * @throws {Error} This method may throw an error
   */
  async createGame(signer: JsonRpcSigner, gameData: CreateGameParams) {
    const {payableAmount, name, file, fee, maxDeposit, minDeposit, roi} =
      gameData;

    const contract = new ethers.Contract(
      GAME_CONTRACTS.gameFactory,
      gameFactoryABI,
      signer
    );

    // ! ethers does not provide generic types
    // eslint shows warnings because of assigning BigNumber to any type

    // eslint-disable @typescript-eslint/no-unsafe-assignment
    const tx = (await contract.createGame(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      fee,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      maxDeposit,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      minDeposit,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      roi,
      file,
      name,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      {value: payableAmount}
    )) as TransactionResponse;
    await tx?.wait();
  }

  async createGameSolInit(
    gameData: CreateGameParamsSolInit,
    walletAddress: string
  ) {
    const {
      name,
      thumbnailUrl,
      fee,
      maxDeposit,
      minDeposit,
      roi,
      duration,
      coinAddress: currencyAddress,
    } = gameData;

    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    const body = {
      name,
      walletAddress,
      roi,
      minDeposit,
      maxDeposit,
      thumbnailUrl,
      fee,
      network: 'sol',
      duration,
      currencyAddress,
    };

    const url = `/api/games/initialize`;
    const res = await this._api.post(url, body, {
      headers: {
        authorization: token,
      },
    });

    const transaction = Transaction.from(Buffer.from(res.data.tx, 'base64'));
    return {transaction, gameId: res.data.gameId};
  }

  async createGameSol(gameData: CreateGameParamsSol) {
    const {gameInfo, signedTx, gameId} = gameData;

    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    const url = `/api/games`;
    const res = await this._api.post(
      url,
      {
        signedTx,
        name: gameInfo.name,
        thumbnailUrl: gameInfo.thumbnailUrl,
        gameId,
        duration: gameInfo.duration,
        coinAddress: gameData.gameInfo.coinAddress,
      },
      {
        headers: {
          authorization: token,
        },
      }
    );

    return res.data as Game;
  }

  async depositSolInit(betData: {
    gameId: number;
    walletAddress: string;
    amount: string;
    currencyAddress: string;
    refWallet?: string;
  }) {
    const {gameId, walletAddress, amount, currencyAddress} = betData;
    const network = 'sol';

    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    const url = `/api/bets/deposit/initiate`;

    const body = {
      gameId,
      walletAddress,
      amount: Math.ceil(Number(amount)),
      network,
      currencyAddress,
      ...(betData.refWallet && {refWallet: betData.refWallet}),
    };

    console.log(body);

    const res = await this._api.post(url, body, {
      headers: {
        authorization: token,
      },
    });

    const transaction = Transaction.from(Buffer.from(res.data.tx, 'base64'));
    return {transaction, betId: res.data.betId as number};
  }

  async depositSol(betData: {
    gameId: number;
    walletAddress: string;
    amount: string;
    signedTx: string;
    betId: number;
    refWallet?: string;
  }) {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    const url = `/api/bets/deposit`;
    const body = {
      gameId: betData.gameId,
      walletAddress: betData.walletAddress,
      amount: Math.round(Number(betData.amount)),
      signedTx: betData.signedTx,
      betId: betData.betId,
      refWallet: betData.refWallet,
    };
    console.log(body);

    const res = await this._api.post<string, {success: boolean}>(url, body, {
      headers: {
        authorization: token,
      },
    });

    return res;
  }

  async deposit(
    signer: JsonRpcSigner,
    contractAddress: string,
    payableAmount: BigNumberish
  ) {
    try {
      const contract = new ethers.Contract(contractAddress, gameABI, signer);

      const tx = (await contract.deposit({
        value: payableAmount,
      })) as TransactionResponse;

      await tx.wait();
      return true;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getWithdrawBet(
    gameId: Game['id'],
    betId: Bet['id'],
    wallet: string,
    isJackpot = false,
    network: 'sol' | 'arb' = 'sol'
  ) {
    try {
      const url = isJackpot
        ? `/api/bets/withdraw/jackpot?network=${network}`
        : `/api/bets/withdraw?network=${network}`;
      const {data} = await this._api.get<WithdrawResponse>(url, {
        params: {
          gameId: gameId,
          betId: betId,
          wallet,
        },
      });

      return data;
    } catch (error) {
      // console.log('getWithdrawBet err: ', error);
      // const {addToast} = useDashboardStore.getState();
      // if (error instanceof AxiosError) {
      //   addToast({
      //     status: 'error',
      //     children: error.response?.data?.message || error.message,
      //   });
      // } else if (error instanceof Error) {
      //   addToast({
      //     status: 'error',
      //     children: error.message || 'Oops, something went wrong',
      //   });
      // }
    }
    return null;
  }

  /**
   * @throws {Error} This method may throw an error
   */
  async withdraw(
    game: Game,
    bet: Bet,
    wallet: string,
    signer?: JsonRpcSigner,
    network: 'sol' | 'arb' = 'sol'
  ) {
    const isJackpot = bet.status === BetStatus.JACKPOT_WITHDRAW_AVAILABLE;

    const url = isJackpot
      ? `/api/bets/withdraw/jackpot?network=${network}`
      : `/api/bets/withdraw?network=${network}`;
    const {data} = await this._api.get<WithdrawResponse>(url, {
      params: {
        gameId: game.id,
        betId: bet.id,
        wallet,
      },
    });

    if (!data) throw new Error('No data found for withdraw request');

    if (network === 'arb' && signer) {
      const contract = new ethers.Contract(
        game.contractAddress,
        gameABI,
        signer
      );

      const tx = (await contract.withdraw(
        isJackpot,
        bet.id,
        data.data.amount,
        data.data.nonce,
        data.signarute
      )) as TransactionResponse;

      await tx.wait();
    }
    return data;
  }

  async withdrawSolInit(game: Game, bet: Bet, wallet: string, amount: number) {
    const decimals = (await getTokenDecimals(game.currencyAddress)) ?? 6;
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    const host = await authApi.getUserById(game.hostId);
    if (!host) {
      throw new Error('No game host found');
    }

    const url = `/api/bets/withdraw/initialize`;
    const {data} = await this._api.post(
      url,
      {
        betId: bet.betId,
        walletAddress: wallet,
        gameId: Number(game.id),
        amount:
          bet.status === BetStatus.JACKPOT_WITHDRAW_AVAILABLE
            ? amount / 10 ** decimals
            : Number(bet.amountForWithdraw) / 10 ** decimals,
        isJackpotValidated: bet.status === BetStatus.JACKPOT_WITHDRAW_AVAILABLE,
        gameCreatorAddress: host.wallet,
        currencyAddress: game.currencyAddress,
      },
      {
        headers: {
          authorization: token,
        },
      }
    );

    const transaction = Transaction.from(Buffer.from(data.tx, 'base64'));
    return transaction;
  }

  async withdrawSol(data: {
    gameId: number;
    walletAddress: string;
    betId: number;
    signedTx: string;
  }) {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    const url = `/api/bets/withdraw`;
    const res = await this._api.post<string, {success: boolean}>(
      url,
      {
        ...data,
      },
      {
        headers: {
          authorization: token,
        },
      }
    );

    return res;
  }
}

export const gameService = new GameService();
