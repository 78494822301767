import {useWallet} from '@solana/wallet-adapter-react';
import {useWeb3React} from '@web3-react/core';
import {AnimatePresence} from 'framer-motion';
import {tryDeactivate} from 'lib/web3-react/connections';
import {useDashboardStore} from 'screens/Dashboard/store/store';
import {DialogPortal, DialogRoot} from 'ui-kit/Dialog';

interface DisconnectWalletDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onSwitchAddress: () => void;
  onDisconnected?: () => void;
}

export function DisconnectWalletDialog({
  isOpen,
  onOpenChange,
  onSwitchAddress,
  onDisconnected,
}: DisconnectWalletDialogProps) {
  const {connected, wallet} = useWallet();
  const {addToast} = useDashboardStore();
  const {connector} = useWeb3React();

  const handleSwitchAddress = () => {
    onOpenChange(false);
    onSwitchAddress();
  };

  const handleDisconnect = () => {
    if (connected) {
      try {
        wallet?.adapter
          .disconnect()
          .then(() => {
            onOpenChange(false);
            addToast({
              status: 'success',
              children: 'Your wallet has been disconnected',
            });
            onDisconnected?.();
          })
          .catch(() => {
            console.warn('Failed to disconnect wallet');
          });
      } catch (e: any) {
        console.error(e.message);
      }
      return;
    }

    try {
      if (!connector) throw new Error('No connector');
      tryDeactivate(connector)
        .then(() => {
          onOpenChange(false);
          addToast({
            status: 'success',
            children: 'Your wallet has been disconnected',
          });
          onDisconnected?.();
        })
        .catch(() => {
          console.warn('Failed to disconnect wallet');
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <DialogRoot open={isOpen} onOpenChange={onOpenChange}>
      <AnimatePresence>
        {isOpen && (
          <DialogPortal forceMount>
            <div className="flex flex-col items-center gap-8 p-5 pt-10 sm:p-[3.75rem] sm:pb-[3.125rem]">
              <div className="flex flex-col items-center gap-6">
                <h2 className="text-center text-xl leading-6 text-[#101520] md:text-[1.75rem] md:leading-9">
                  Are you sure you want to <br />
                  disconnect your wallet?
                </h2>
                <p className="max-w-[267px] md:max-w-[369px] text-center text-sm leading-5 text-[#8C8C8C]">
                  It may take up to 5 min to propagate
                </p>
              </div>
              <div className="flex flex-col justify-center items-center gap-6">
                <button className="btn" onClick={handleSwitchAddress}>
                  Change wallet connector
                </button>
                <button
                  className="font-medium text-sm leading-5 text-[#DB3C48]"
                  onClick={handleDisconnect}
                >
                  Disconnect wallet
                </button>
              </div>
            </div>
          </DialogPortal>
        )}
      </AnimatePresence>
    </DialogRoot>
  );
}
