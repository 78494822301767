import {useWeb3React} from '@web3-react/core';
import {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Logo} from 'ui-kit/Logo';
import {Icons as SocialIcons} from '../../../Landing/components/Icons';
import {ConnectWalletDialog} from '../ConnectWalletDialog';
import {DisconnectWalletDialog} from '../DisconnectWalletDialog';
import {Icons} from '../Icons';
import './styles.css';
import {AnalyticsDialog} from '../AnalyticsDialog';
import {useWallet} from '@solana/wallet-adapter-react';

export function DashboardAside() {
  const [isConnectWalletOpen, setIsConnectWalletOpen] = useState(false);
  const [isDisconnectWalletOpen, setIsDisconnectWalletOpen] = useState(false);
  const {account, isActive} = useWeb3React();
  const {connected, publicKey} = useWallet();

  let address: string | undefined = undefined;

  if (account) {
    address = account;
  } else if (publicKey) {
    address = publicKey.toString();
  }

  const isOwner = !!address && address === process.env.REACT_APP_OWNER_WALLET;

  return (
    <aside className="dashboard-aside">
      <div className="mt-10 ml-10">
        <Logo />
      </div>
      <nav className="flex flex-col gap-5 mt-24 pl-6">
        <NavLink to="/dashboard" end className="dashboard-aside-nav-item">
          <div className="dashboard-aside-nav-item-icon">
            <Icons.AllGames />
          </div>
          All games
          <div className="dashboard-aside-nav-item-active" />
        </NavLink>
        <NavLink to="/dashboard/my" end className="dashboard-aside-nav-item">
          <div className="dashboard-aside-nav-item-icon">
            <Icons.MyGames />
          </div>
          My games
          <div className="dashboard-aside-nav-item-active" />
        </NavLink>
        <NavLink
          to="/dashboard/hosted"
          end
          className="dashboard-aside-nav-item"
        >
          <div className="dashboard-aside-nav-item-icon">
            <Icons.HostedGames />
          </div>
          Hosted games
          <div className="dashboard-aside-nav-item-active" />
        </NavLink>
        <NavLink to="/faq" className="dashboard-aside-nav-item">
          <div className="dashboard-aside-nav-item-icon">
            <Icons.FAQ />
          </div>
          FAQs
          <div className="dashboard-aside-nav-item-active" />
        </NavLink>
        <NavLink to="/referrals" className="dashboard-aside-nav-item">
          <div className="dashboard-aside-nav-item-icon">
            <Icons.Referrals />
          </div>
          Referrals
          <div className="dashboard-aside-nav-item-active" />
        </NavLink>
        {isOwner && (
          <AnalyticsDialog>
            <button className="dashboard-aside-nav-item">
              <div className="dashboard-aside-nav-item-icon">
                <Icons.Analitycs fill="white" />
              </div>
              All comission fees
              <div className="dashboard-aside-nav-item-active" />
            </button>
          </AnalyticsDialog>
        )}
      </nav>
      <div className="flex mt-auto items-center mb-[2.88rem]">
        {(isActive || connected) && (
          <button
            className="dashboard-aside-logout"
            onClick={() => setIsDisconnectWalletOpen(true)}
          >
            Log out
            <Icons.LogOut />
          </button>
        )}
        <div className="flex gap-4 mr-8 ml-auto">
          <a
            href="https://x.com/ponzidotmarket"
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center items-center w-6 h-6"
          >
            <SocialIcons.X />
          </a>
          <a
            href="https://t.me/ponzidotmarket"
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center items-center w-6 h-6"
          >
            <SocialIcons.Telegram />
          </a>
        </div>
      </div>
      <DisconnectWalletDialog
        isOpen={isDisconnectWalletOpen}
        onOpenChange={val => setIsDisconnectWalletOpen(val)}
        onSwitchAddress={() => setIsConnectWalletOpen(true)}
      />
      <ConnectWalletDialog
        isOpen={isConnectWalletOpen}
        onOpenChange={val => setIsConnectWalletOpen(val)}
      />
    </aside>
  );
}
