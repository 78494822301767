import {Coin} from 'types';
import {API_BASE_URL} from '../constants';
import axios from 'axios';

const minute = 60_000;

type Cache = {
  value: string;
  timestamp: number;
};

class PriceService {
  private _loading = false;
  private _cache: Cache = JSON.parse(
    localStorage.getItem('price_cache') ||
      JSON.stringify({
        value: {},
        timestamp: 0,
      })
  ) as Cache;
  private _coins_cache: Cache = JSON.parse(
    localStorage.getItem('coins_cache') ||
      JSON.stringify({
        value: {},
        timestamp: 0,
      })
  ) as Cache;

  private _timeout = minute * 1;

  async fetchCoins(): Promise<Coin[]> {
    if (this._coins_cache.timestamp + this._timeout * 60 > Date.now()) {
      return JSON.parse(this._coins_cache.value);
    }
    const response = await axios.get(`${API_BASE_URL}/api/ponzi/get-coins`);
    this._coins_cache = {
      value: JSON.stringify(response.data),
      timestamp: Date.now(),
    };
    localStorage.setItem('coins_cache', JSON.stringify(this._coins_cache));
    return JSON.parse(this._coins_cache.value);
  }

  async fetchPrice(addr: string) {
    if (this._loading) {
      return {value: '0', timestamp: 0} as Cache;
    }
    const localCache = localStorage.getItem(`price-${addr}`);
    if (localCache) {
      const cache: Cache = JSON.parse(localCache);
      if (cache.timestamp + this._timeout > Date.now()) {
        return cache.value;
      }
    }
    this._loading = true;

    const response = await axios.get(
      `${API_BASE_URL}/api/ponzi/get-price?address=${addr}`
    );
    const fetchedPrice = response.data['price'];
    this._loading = false;

    localStorage.setItem(
      `price-${addr}`,
      JSON.stringify({value: fetchedPrice, timestamp: Date.now()})
    );
    return {value: fetchedPrice, timestamp: Date.now()} as Cache;
  }
  get(address: string) {
    this.fetchPrice(address).catch(console.warn);
    const tokenPrice = this._cache;
    return tokenPrice || '0';
  }
}
export const Price = new PriceService();
