import {create} from 'zustand';
import {Referral} from 'screens/Referrals/components/ReferralList';

interface AllRefsStore {
  referrals: Referral[];
  isLoading: boolean;
  page: number;
  hasMore: boolean;
  totalGames: number;
  setRefs: (refs: Referral[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  setPage: (page: number) => void;
  setHasMore: (hasMore: boolean) => void;
}

export const allRefsStore = create<AllRefsStore>((set, get) => ({
  referrals: [],
  isLoading: true,
  page: 0,
  hasMore: true,
  totalGames: 0,
  setRefs: refs => set({referrals: refs, isLoading: false}),
  setIsLoading: isLoading => set({isLoading}),
  setPage: page => set({page}),
  setHasMore: hasMore => set({hasMore}),
}));

export const useAllRefsStore = allRefsStore;
