import {SolanaValue} from './SolanaValue';
// import {useIsMedium} from 'lib/useBreakpoints';
// import {useWallet} from '@solana/wallet-adapter-react';

export function EthereumValue({
  value,
  icon,
  symbol,
}: {
  value: number;
  icon?: string;
  symbol?: string;
}) {
  // const isMd = useIsMedium();
  // const {connected} = useWallet();
  // hardcoded for now, only solana will be supported for data displayment
  return (
    <>
      <SolanaValue icon={icon} symbol={symbol} value={value} />
    </>
  );
}
