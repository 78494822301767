import {Buffer} from 'buffer';
import {Web3ContextProvider} from 'lib/web3-react/components/Web3React';
import {Web3Interceptor} from 'lib/web3-react/components/Web3Interceptor';
import {useEffect} from 'react';
import {createHashRouter, Navigate, RouterProvider} from 'react-router-dom';
import {Price} from 'services/price';
import {SolanaBanner} from './components/SolanaBanner'; // Updated import path
import {AllGamesView} from 'screens/AllGames';
import {DashboardView} from 'screens/Dashboard';
import {FAQView} from 'screens/FAQ';
import {HostedGamesView} from 'screens/HostedGames';
import {MyGamesView} from 'screens/MyGames';
import {TermsAndConditionsView} from 'screens/TermAndConditions';
import ReferralsView from 'screens/Referrals/ReferralsView';
import {useWallet} from '@solana/wallet-adapter-react';

const router = createHashRouter([
  {
    path: '/',
    // element: <LandingView />,
    element: <Navigate to="/dashboard" replace />,
  },
  {
    path: '/',
    element: (
      <Web3Interceptor>
        <DashboardView />
      </Web3Interceptor>
    ),
    children: [
      {
        path: '/dashboard',
        element: <AllGamesView />,
      },
      {
        path: '/dashboard/my',
        element: <MyGamesView />,
      },
      {
        path: '/dashboard/hosted',
        element: <HostedGamesView />,
      },
      {
        path: '/referrals',
        element: <ReferralsView />,
      },
      {
        path: '/faq',
        element: <FAQView />,
      },
      {
        path: '/terms',
        element: <TermsAndConditionsView />,
      },
    ],
  },
]);

window.Buffer = Buffer;

export function App() {
  useEffect(() => {
    const fetchCoins = async () => {
      const coins = await Price.fetchCoins();
      for (const coin of coins) {
        await Price.fetchPrice(coin.address).catch(console.warn);
      }
    };
    fetchCoins().catch(console.error);
  }, []);

  const {publicKey} = useWallet();

  const wl = [
    'FFHcNkTStHbHXXtAwPjfqHsjbuFWq9yhxP4AaLmrsKD7',
    'CUCaD2juuf88EtiH7YtrmzMRrk58Lb4ajbV8nbnqzfjK',
  ];

  const isMaintenanceMode = false; // Set this dynamically (e.g., via environment variable or config)

  if (isMaintenanceMode && !wl.includes(publicKey?.toString() || '')) {
    return (
      <div>
        <p>We are currently updating our site. Please check back later.</p>
        {/*{!active ? (*/}
        {/*   <ConnectWalletDialog>*/}
        {/*     <button*/}
        {/*       className="dashboard-header-wallet"*/}
        {/*       onClick={() => clearToasts()}*/}
        {/*     >*/}
        {/*       <Icons.Wallet />*/}
        {/*     </button>*/}
        {/*   </ConnectWalletDialog>*/}
        {/* ) : (*/}
        {/*   <WalletSettingsPopover>*/}
        {/*     <button*/}
        {/*       className="dashboard-header-wallet"*/}
        {/*       onClick={() => clearToasts()}*/}
        {/*     >*/}
        {/*       wallet*/}
        {/*     </button>*/}
        {/*   </WalletSettingsPopover>*/}
        {/* )} */}
      </div>
    );
  }

  return (
    <>
      <SolanaBanner />
      <Web3ContextProvider>
        <RouterProvider router={router} />
      </Web3ContextProvider>
    </>
  );
}
